import {getRepository} from 'typeorm/browser';
import {TfSubmissionResult as model} from '../records/tf-submission-result';
import fastPushAll from './fast-push';

export async function push(report) {
  const repo = getRepository(model);
  const data = [];
  report.includes.submissions.forEach((submission) => {
    const {user: {id: userId}, answerIds, payloads} = submission;

    answerIds.forEach((answerId) => {
      const payload = payloads.find((payload) => payload.answerId === answerId)?.text;
      const attributes = {answerId, payload, submissionId: userId};
      data.push(attributes);
    });
  });

  await repo.clear();

  return fastPushAll(repo, model, data);
}
