import {Column, Entity, Index, ManyToOne, OneToMany, PrimaryGeneratedColumn} from 'typeorm/browser';
import {TfQuestion} from './tf-question';
import {TfScore} from "./tf-score";
import {TfSubmissionResult} from "./tf-submission-result";

@Entity('tf-answers')
export class TfAnswer {
    @PrimaryGeneratedColumn()
    id: number;

    @Column({type: "text"})
    text: string;

    @Column({nullable: true})
    keyFinding: string;

    @Column({nullable: true})
    lowRiskRecommendation: string;

    @Column({nullable: true})
    mediumRiskRecommendation: string;

    @Column({nullable: true})
    recommendation: string;

    @Column()
    uuid: string;

    @Column({type: 'bigint'})
    @Index()
    questionId: number

    @ManyToOne((type) => TfQuestion, (question) => question.answers,
        { onDelete: 'CASCADE' })
    question: TfQuestion;

    @OneToMany((type) => TfScore, (score) => score.answer)
    scores: TfScore[];

    @OneToMany((type) => TfSubmissionResult, (submissionResult) => submissionResult.answer)
    submissions: TfSubmissionResult[];
}
