export function addUsers(store, report) {
  store.users = report.includes.submissions.map((s) => s.user);
}

export function addCategories(store, report) {
  store.categories = report.includes.categories;
}

export function addProject(store, report) {
  store.project = report.includes.project;
}

export function addQuestions(store, report) {
  store.questions = report.includes.questions;
}

export function addAnswers(store, report) {
  store.answers = report.includes.answers;
}

export function addAssessment(store, report) {
  store.assessment = report.includes.assessment;
}

export function addReport(store, report) {
  store.report = report;
}
