import {calculateRiskLevel} from '../../../lib/reports/risk-level';
import {isEmpty} from 'lodash';
import LinkAction from '../../atoms/actions/link';
import React, {useMemo, useState} from 'react';
import Recommendation from './recommendation';
import ReportExportRecommendationsExcel from '../exports/recommendations-excel';
import ReportTitle from '../text/title';

export default function ReportRecommendations({store}) {
  const rawRisk = store.helpers.rawRisk(store);
  const riskLevel = calculateRiskLevel(rawRisk.percentage);
  const recommendationKey = `${riskLevel}RiskRecommendation`;

  const answers = useMemo(() => {
    return store.answers.filter((a) => {
      if (a.recommendation) {
        return a;
      }
      if (a[recommendationKey]) {
        return a;
      }
      return false;
    });
  }, [store.report.id, recommendationKey]); // eslint-disable-line

  const showMore = answers.length > 3;
  const [expanded, setExpanded] = useState(false);

  const displayedAnswers = expanded ? answers : answers.slice(0, 3);

  if (isEmpty(displayedAnswers)) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between items-end pb-4">
        <ReportTitle>
          <div className="text-3xl text-black-0 pb-2">Recommendations</div>
        </ReportTitle>

        <div>
          <ReportExportRecommendationsExcel store={store} recommendationKey={recommendationKey} answers={answers}/>
        </div>
      </div>

      <div className="flex items-baseline">
        <div className="pt-4 text-text-1">
          Based on the answers provided, recommended considerations include the following:
        </div>

        {
          showMore ?
          <LinkAction className="ml-2 text-sm" onClick={() => setExpanded(!expanded)}>
            { expanded ?
              'Hide' :
              `Show all (${answers.length})`
            }
          </LinkAction> :
          null
        }
      </div>

      <div>
        {displayedAnswers.map((answer, index) => (<Recommendation recommendationKey={recommendationKey} key={index} answer={answer}/>))}
      </div>
    </div>
  );
}
