import React from 'react';

export default function TypeformScoreViewer({category, score, setEditor}) {
  return (
    <div className="text-center flex flex-col cursor-pointer" onClick={() => {
      setEditor(true);
    }}>
      <div className="font-semibold">
        {category.title}
      </div>

      <div className="pt-2">
        {score?.score}
      </div>
    </div>
  );
}
