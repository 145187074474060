export default function colorForScore(value, category) {
  const {mainCategory} = category;
  if (mainCategory === 'AI Readiness') {
    return colorForSuccess(value);
  }

  if (mainCategory === 'Knowledge Gaps') {
    return colorForError(value);
  }

  let bgColor;
  if (value === 1) {
    bgColor = 'bg-yellow-1';
  } else if (value === 2) {
    bgColor = 'bg-orange-1';
  } else {
    bgColor = 'bg-red-4';
  }

  return bgColor;
}

function colorForSuccess(value) {
  let bgColor;
  if (value === 1) {
    bgColor = 'bg-light-green-1';
  } else if (value === 2) {
    bgColor = 'bg-light-green-2';
  } else {
    bgColor = 'bg-light-green-3';
  }

  return bgColor;
}

function colorForError(value) {
  let bgColor;
  if (value === 1) {
    bgColor = 'bg-light-red-1';
  } else if (value === 2) {
    bgColor = 'bg-light-red-2';
  } else {
    bgColor = 'bg-light-red-3';
  }

  return bgColor;
}

// function colorForScoreReversed(value) {
//   let bgColor;
//   if (value === 1) {
//     bgColor = 'bg-red-4';
//   } else if (value === 2) {
//     bgColor = 'bg-yellow-1';
//   } else {
//     bgColor = 'bg-green-1';
//   }
//
//   return bgColor;
// }
