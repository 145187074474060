export default function updateScoreTypeform(currentTypeform, action) {
  const newTypeForm = JSON.parse(JSON.stringify(currentTypeform));
  const {score, scoreRecord, answer} = action;

  let newAnswer;

  const question = newTypeForm.questions.find((q) => {
    return q.answers.find((a) => {
      if (a.id === answer.id) {
        newAnswer = a;
        return true;
      }
    });
  });

  if (newAnswer) {
    const newScore = newAnswer.scores.find((score) => {
      return score.id === scoreRecord.id;
    });

    newScore.score = score;
  }
  question.status = 'verified';

  question.answers.forEach((answer) => {
    answer.status = 'verified';
  });

  newTypeForm.form.status = newTypeForm.questions.find((q) => q.status === 'unverified') ? 'unverified' : 'verified';

  return newTypeForm;
}
