import {isEmpty} from 'lodash';
import Groups from './groups';
import React, {useMemo} from 'react';

export default function KeyFindings({mainCategory, store, keyFindingsFiltertedCategories}) {
  const keyFindings = useMemo(() => {
    return store.helpers.keyFindingsForMainCategory(mainCategory, store);
  }, [mainCategory]); // eslint-disable-line

  if (isEmpty(keyFindings)) {
    return null;
  }

  if (!keyFindingsFiltertedCategories) {
    return null;
  }

  return (
    <div>
      <div>
        <div className="flex items-baseline">
          <h3 className="text-black-1 font-bold text-xl">Key Findings</h3>
        </div>

      </div>

      <div className="text-text-1 py-3">
        <Groups store={store} keyFindings={keyFindings} keyFindingsFiltertedCategories={keyFindingsFiltertedCategories}/>
      </div>
    </div>
  );
}
