import {Link} from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import useAuthorization from '../../hooks/use-authorization';

export default function PageFooter() {
  const {loggedIn} = useAuthorization({protectedRoute: false});
  const year = new DateFnsUtils().getYear(new Date());

  if (!loggedIn) {
    return null;
  }

  function LinkWrapper({...options}) {
    const className = 'text-light-blue-1 mx-5';
    return <Link className={className} {...options}/>;
  }

  return (
    <div className="mt-5 bg-white-1 bottom-0 px-7 py-4">
      <div className="flex items-center justify-between">
        <div className="w-1/2">
          <b className="text-text-1 text-xl">Canari</b>
          <div className="text-text-1 text-sm">
            An INQ Data Law product. Copyright © {year}.
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-1/2 md:justify-end text-right">
          <LinkWrapper to="/about">
            About
          </LinkWrapper>
          <LinkWrapper to="/privacy">
            Privacy
          </LinkWrapper>
          <LinkWrapper to="/terms-and-conditions">
            Terms & Conditions
          </LinkWrapper>
          <LinkWrapper to="/help">
            <span className="text-pink-3">
              Help & Support
            </span>
          </LinkWrapper>
        </div>
      </div>
    </div>
  );
}
