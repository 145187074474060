import {Helmet} from 'react-helmet-async';
import React from 'react';

export default function Head() {
  return (
    <Helmet>
      <title>INQ</title>
      <meta charSet="utf-8" />
    </Helmet>
  );
}
