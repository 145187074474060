import React, {useState} from 'react';
import TrixField from './trix';
import style from './text-area.module.scss';

export default function TypeformTextArea({form, answer, field, name}) {
  const [editor, setEditor] = useState(false);
  const [displayName, setDisplayName] = useState(name);

  return (
    <div className="my-4">
      <span>
        {displayName}:
      </span>

      {
        editor ?
          <TrixField setDisplayName={setDisplayName} form={form} answer={answer} field={field} name={name} setEditor={setEditor}/> :
          <div>
            <div
              dangerouslySetInnerHTML={{__html: answer[field]}}
              className={`border-black border-solid border-2 p-3 cursor-pointer ${style.textArea}`}

              onClick={() => {
                setEditor(true);
              }}
            >
            </div>
          </div>
      }
    </div>
  );
}
