import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';

export default function IsMember({children, strict = false}) {
  return isMember(children, strict);
}

export const isMember = function(component, strict) {
  const {currentUser} = useSelector((state) => state.session, (prev, next) => { // eslint-disable-line
    return isEqual(prev, next);
  });

  if (!currentUser) {
    return null;
  }

  const {role} = currentUser;

  if (strict && role !== 'member') {
    return null;
  }

  return component;
};
