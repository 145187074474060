import {isEmpty, sortBy} from 'lodash';
import Group from './group';
import React, {useMemo, useState} from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

export default function Groups({store, keyFindings, keyFindingsFiltertedCategories}) {
  const [activeCategory, setActiveCategory] = useState('0');

  const groupedKfs = useMemo(() => {
    const categoryRecords = keyFindingsFiltertedCategories.filter((tc) => tc.percentage > 0);

    const grouped = categoryRecords.map((category) => {
      const filteredKeyFindings = keyFindings.filter((kf) => {
        return kf.answer.scores.find((score) => {
          return score.categoryId === category.id;
        });
      });

      return {
        category,
        keyFindings: filteredKeyFindings,
      };
    });

    const filtered = grouped.filter((kf) => {
      return !isEmpty(kf.keyFindings);
    });

    const sorted = sortBy(filtered, (kf) => {
      return kf.category.percentageTitle;
    });

    sorted.push({
      category: {id: 'all', percentageTitle: 'All'},
      keyFindings,
    });

    return sorted;
  }, [store.report.id]); // eslint-disable-line

  function changeTab(_, value) {
    setActiveCategory(value);
  }

  return (
    <div id="report-category-kf-switcher">
      <TabContext value={activeCategory}>
        <TabList onChange={changeTab} aria-label="kf switcher" scrollButtons="on">
          {groupedKfs.map((kf, index) => {
            const title = kf.category.percentageTitle;

            return (
              <Tab value={index.toString()} label={title} key={kf.category.id}/>
            );
          })}
        </TabList>

        {groupedKfs.map((groupeKfs, index) => {
          return (
            <TabPanel value={index.toString()} key={groupeKfs.category.id} className="pl-0">
              <Group groupeKfs={groupeKfs}/>
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
}
