import {Link} from 'react-router-dom';
import {isMember} from '../authorization/is-member';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import LogoReleaseCanary from '../logos/release/canary';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NavBarListLinks from './navbar/list-links';
import NavbarRightButton from './navbar/right-button';
import React, {forwardRef, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import logoutAction from '../../actions/session/logout';
import useAuthorization from '../../hooks/use-authorization';
import useWindowSize from '../../hooks/use-window-size';

export default function NavbarPage() {
  const {currentUser, loggedIn} = useAuthorization({protectedRoute: false});

  const {isMobile} = useWindowSize();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const {pathname} = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const menuId = 'navbar-menu';

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  function closeMenuItem(e) {
    if (e.target.tagName !== 'LI') {
      return handleMenuClose();
    }
    const link = e.target.querySelector('a');
    if (!link) {
      return;
    }
    link.click();
    handleMenuClose();
  }

  function logout() {
    enqueueSnackbar('Logged out');
    dispatch(logoutAction());
    history.push('/');
  }

  const WrappedMenuitem = forwardRef(({children, ...options}, ref) => { // eslint-disable-line
    return (
      <MenuItem onClick={closeMenuItem} ref={ref} {...options}>
        {children}
      </MenuItem>
    );
  });

  const nabarWrapperClass = loggedIn || pathname !== '/login' ? 'visibile opacity-1' : 'invisible opacity-0';


  return (
    <div className={nabarWrapperClass}>
      <AppBar position="static">
        <Toolbar className="bg-blue-1">
          <Link to="/">
            <div className="bg-white-1 py-2 px-6">
              <LogoReleaseCanary className="cursor-pointer h-12 transform hover:scale-105 duration-300"/>
            </div>
          </Link>
          {isMobile ?
          null :
          <div className="pl-1">
            <NavBarListLinks loggedIn={loggedIn}/>
          </div>
          }
          <div className="flex-grow" />
          <NavbarRightButton isMobile={isMobile} handleProfileMenuOpen={handleProfileMenuOpen}
            currentUser={currentUser} menuId={menuId}/>
        </Toolbar>
      </AppBar>


      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && isOpen}
        transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
        onClose={handleMenuClose}
      >

        {isMember(
            <WrappedMenuitem>
              <Link to="/dashboard/profile"> My profile</Link>
            </WrappedMenuitem>,
        )}

        {isMember(
            <WrappedMenuitem>
              <a onClick={logout}>
                Sign out
              </a>
            </WrappedMenuitem>,
        )}
      </Menu>
    </div>
  );
}
