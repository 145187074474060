import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import {rootReducer} from '../../../reducers/root';
import React from 'react';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

let composeEnhancer = compose;
if (!global.isSSR && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  const extensionOptions = {
    trace: true,
  };
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(extensionOptions);
}

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk, logger)));

export default function Store({children}) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}
