import {getRepository} from 'typeorm/browser';
import {TfReport as model} from '../records/tf-report';
import fastPushAll from './fast-push';

export async function push(report) {
  const repo = getRepository(model);
  const data = [];

  data.push({
    createdAt: report.createdAt,
    id: report.id,
    projectTitle: report.includes.project.title,
  });

  await repo.clear();

  return fastPushAll(repo, model, data);
}
