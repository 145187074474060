import Initializer from './config/initializer';
import React from 'react';
import Routes from './config/routes';

export default function App({config, request}) {
  return (
    <Initializer config={config} request={request} >
      <Routes/>
    </Initializer>
  );
}
