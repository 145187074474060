import React, {useState} from 'react';
import ScoreEditor from './score/editor';
import TypeformScoreViewer from './score/score-viewer';
export default function TypeformScore({form, category, score, answer}) {
  const [editor, setEditor] = useState(false);

  return (
    <div className="h-full score-container">
      <div className="m-4 h-16 w-32" tabIndex="0" onFocus={() => setEditor(true)}>
        {
          editor ?
            <ScoreEditor form={form} category={category} score={score} answer={answer} setEditor={setEditor}/> :
            <TypeformScoreViewer category={category} score={score} setEditor={setEditor}/>
        }
      </div>
    </div>
  );
}
