import React from 'react';
import ReportExportResponsesExcel from './exports/responses-excel';
import ReportTitleWrapper from './text/title';

export default function ReportTitle({store}) {
  const {report, assessment, project} = store;

  return (
    <div className="text-text-1">
      <ReportTitleWrapper className="mb-2 font-semibold">
        <div className="text-black-0 text-4xl">
          {project.title}
        </div>
        <div className="text-black-1 text-2xl mt-6">
          {assessment.phase}
        </div>
      </ReportTitleWrapper>

      <div className="text-sm">
        <ReportExportResponsesExcel report={report} assessment={assessment} project={project} />
      </div>
    </div>
  );
}
