import {WebSql} from './config/initializers/web-sql';
import {camelize} from '@ridi/object-case-converter';
import React, {useEffect, useState} from 'react';
import ReportsLayout from './reports/layout';
const Router = require('react-router-dom').BrowserRouter;

export default function MountReport({report_id: reportId}) {
  const [report, setReport] = useState();
  useEffect(() => {
    (async function() {
      const response = await fetch(`/api/reports/${reportId}`);
      const json = await response.json();
      setReport(camelize(json, {recursive: true}));
    })();
  }, [reportId, setReport]);

  if (!report) {
    return <div>
      <h3>Fetching report</h3>
    </div>;
  }


  return (
    <div className="bg-light-gray-2 px-6 py-6 pr-24">
      <Router>
        <WebSql>
          <ReportsLayout report={report}/>
        </WebSql>
      </Router>
    </div>
  );
}
