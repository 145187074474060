import {groupBy, isEmpty, sortBy} from 'lodash';
import PositiveImpactTabPanelQuestion from './question';
import React, {useMemo} from 'react';

export default function PositiveImpactTabPanel({category, expanded}) {
  const {currentScore} = category;

  const entriesWithPayloads = useMemo(() => {
    if (isEmpty(currentScore)) {
      return [];
    }

    const {submissionResults} = currentScore;
    const groupedQuestions = (groupBy(submissionResults, (submissionResult) => {
      return submissionResult.answer.questionId;
    }));

    const entries = sortBy(Object.entries(groupedQuestions), (group) => {
      return group[0];
    });

    return entries.map((entry) => {
      const submissionResults = entry[1];
      const payloads = submissionResults.map((sr) => sr.payload).filter((payload) => payload);

      return {payloads: payloads, question: submissionResults[0].answer.question};
    }).filter(({payloads}) => {
      return payloads.length > 0;
    });
  }, [currentScore]);

  const displayedEntriesWithPayloads = expanded ? entriesWithPayloads : entriesWithPayloads.slice(0, 3);

  return (
    <div>
      {displayedEntriesWithPayloads.map(({question, payloads}) => {
        return (
          <PositiveImpactTabPanelQuestion question={question} payloads={payloads} key={question.id}/>
        );
      })}
    </div>
  );
}
