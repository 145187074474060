import {TfCategory} from '../../records/tf-category';
import {TfReport} from '../../records/tf-report';
import {categorySubmissionScores} from './category-submission-scores';
import {getRepository} from 'typeorm/browser';

export async function rawRiskLevel() {
  const report = await getRepository(TfReport).findOne();
  const rawRisk = await getRepository(TfCategory).findOne({title: 'Raw risk'});
  const currentScore = await categorySubmissionScores(rawRisk);

  return {
    currentScore,
    rawRisk,
    report,
  };
}
