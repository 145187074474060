import {Column, Entity, OneToMany, PrimaryGeneratedColumn} from 'typeorm/browser';
import {TfAnswer} from './tf-answer';

@Entity('tf-questions')
export class TfQuestion {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({type: "text"})
  text: string;

  @Column()
  questionType: string;

  @Column()
  uuid: string;

  @OneToMany((type) => TfAnswer, (answer) => answer.question)
  answers: TfAnswer[];
}
