import {addMissingScores} from './add-missing-scores';
import axios from '../../lib/axios';

export default function tfFormFetchAction(formId) {
  const url = `typeforms/${formId}`;

  return (dispatch) => {
    axios().get(url).then(({data: {tfForm}}) => {
      dispatch(tfFormFetch(addMissingScores(tfForm)));
    });
  };
}

export function tfFormFetch(typeform) {
  return {
    type: 'TYPEFORM/FETCH',
    typeform,
  };
}
