import {findKnowledgeGaps} from '../../../lib/reports-sql/queries/knowledge-gaps';
import {isEmpty} from 'lodash';
import DebugWindowReports from '../debug/window';
import KnowledgeGap from './knowledge-gap';
import LinkAction from '../../atoms/actions/link';
import React, {useEffect, useState} from 'react';
import ReportTitle from '../text/title';
import useIsDebug from '../../../hooks/use-is-debug';

export function KnowledgeGaps() {
  const {isDebugMode} = useIsDebug();
  const [knowledgeGaps, setKnowledgeGaps] = useState();
  const [debugSql, setDebugSql] = useState();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    findKnowledgeGaps().then(({answers, query}) => {
      setKnowledgeGaps(answers);
      if (isDebugMode) {
        setDebugSql(query.getSql());
      }
    });
  }, [isDebugMode, setKnowledgeGaps, setDebugSql]);

  if (isEmpty(knowledgeGaps)) {
    return null;
  }

  const showMore = knowledgeGaps.length > 3;
  const displayedKnowledgeGaps = expanded ? knowledgeGaps : knowledgeGaps.slice(0, 3);

  return (
    <div>
      {
        isDebugMode &&<DebugWindowReports
          title="Knowledge gaps"
          shortMessage="Get all answers from knowledge gap category"
          message="Then filter to find those with 100% response rate. Then order them by the score"
          position="right"
          debugSql={debugSql}
          data={knowledgeGaps}
        />
      }

      <div className="flex items-baseline">
        <div>
          <ReportTitle>
            <div className="text-3xl text-black-0 pb-2">Knowledge Gaps</div>
          </ReportTitle>
        </div>
        <div>
          {
            showMore ?
              <LinkAction className="ml-2 text-sm" onClick={() => setExpanded(!expanded)}>
                { expanded ?
                  'Hide' :
                  `Show all (${knowledgeGaps.length})`
                }
              </LinkAction> :
              null
          }
        </div>
      </div>

      <div>
        {displayedKnowledgeGaps.map((knowledgeGap) =>
          <KnowledgeGap answer={knowledgeGap} key={knowledgeGap.id}/>,
        )}
      </div>
    </div>
  );
}
