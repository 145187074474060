import React from 'react';
import ReportOutstandingQuestionAnswer from './answer';

export default function ReportOutstandingQuestion({usersCount, question}) {
  const answers = question.answers;
  const questionsCount = answers.length - 1;

  return (
    <div className="my-4 py-4 px-4 bg-light-yellow-1 shadow-md">
      <div className="font-bold text-black-1">
        {question.text}
      </div>

      <div className="pt-3">
        {answers.map((answer, index) =>
          <ReportOutstandingQuestionAnswer size={questionsCount} index={index} answer={answer} key={answer.id} usersCount={usersCount}/>,
        )}
      </div>
    </div>
  );
}
