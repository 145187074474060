export function getHookStateProperties(object, ...properties) {
  const result = {};
  properties.forEach((prop) => {
    let val = object[prop];
    if (val.get instanceof Function) {
      val = val.get();
    }

    result[prop] = val;
  });

  return result;
}
