import {compact} from 'lodash';
import ExportsDownloadButton from './download';
import React from 'react';
import downloadParams from '../../../lib/reports/download-params';

export default function ReportExportRecommendationsExcel({store, recommendationKey, answers}) {
  const {report, assessment, project} = store;
  const data = {
    recommendations: compact(answers.map((answer) => {
      return answer[recommendationKey] ? answer[recommendationKey] : answer.recommendation;
    })),
  };

  const url = `report_exports/${report.id}/excel_recommendations`;
  const fileName = `Canari recommendations for ${project.title} - ${assessment.phase}.xlsx`;

  const params = downloadParams(store, data);

  return (
    <ExportsDownloadButton fileName={fileName} text={'export as Excel'} params={params} data={data} store={store} url={url}/>
  );
}
