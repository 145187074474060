import {calculatePercentage} from './risk-level';

export function calculateRiskPerCategory({helpers, categories, users}) {
  categories.forEach((category) => {
    category.current = helpers.calculateCurrentForCategory(category, users);
    const {current, total} = category;

    category.percentage = parseFloat(calculatePercentage(current, total).toFixed(2));

    const {displayTitle, title: defaultTitle, percentage} = category;
    const title = displayTitle || defaultTitle;

    category.percentageTitle = `${title} ${percentage}%`;
  });
}

export function selectKeyFindings(answerOccurrences, store) {
  const minUsersCount = store.users.length / 2;

  return answerOccurrences.filter((o) => {
    return o.occurrences > minUsersCount;
  });
}
