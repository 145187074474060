import {format} from 'date-fns';

export const humanize = (string) => {
  return string.replace(/[_\-]/g, ' ');
};

export const truncate = (str, maxLen, separator = ' ') => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

export const formatAsDate = (val) =>{
  if (!(val instanceof Date)) {
    val = new Date(val);
  }

  return format(val, 'MMMM dd, yyyy');
};

export function clearClassName(className) {
  if (!className) {
    return '';
  }

  return className;
}


export function wordInString(string, word) {
  return new RegExp( '\\b' + word + '\\b', 'i').test(string);
}
