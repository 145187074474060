import {PrismAsyncLight as SyntaxHighlighter} from 'react-syntax-highlighter';
import {darcula} from 'react-syntax-highlighter/dist/esm/styles/prism';
import DebugWindowReportsData from './data';
import LinkAction from '../../atoms/actions/link';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import sql from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import sqlFormatter from 'sql-formatter';

SyntaxHighlighter.registerLanguage('sql', sql);

export default function DebugWindowReports({title, message, shortMessage, debugSql, data, position = 'left', inlineSmall=false}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState(null);
  const [showTitle, setShowTitle] = useState(false);

  function toggleOpen() {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    const _style = isExpanded ? {} : {
      borderRadius: '1rem', [position]: '-23rem', position: inlineSmall ? 'relative' : 'absolute', width: '20rem',
    };

    if (inlineSmall) {
      delete _style[position];
      delete _style.width;
    }

    if (inlineSmall && !isExpanded) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }

    setStyle(_style);
  }, [position, inlineSmall, isExpanded]);

  if (style === null) {
    return null;
  }

  return (
    <div className="relative">
      <div className="text-text-1 border border-solid border-text-1 text-white p-2" style={style}>
        <LinkAction className="italic text-right" onClick={toggleOpen}>
          Debug
        </LinkAction>
        {
          showTitle &&
            <div>
              <div className="text-lg mb-2 font-bold">
                {title}
              </div>
              <div className="italic">
                {shortMessage}
              </div>
            </div>
        }

        {
          isExpanded &&
          <div className="pt-2">
            <div>
              {message}
            </div>

            {debugSql &&
            <div style={{fontSize: '0.8rem', maxHeight: '50rem', overflowY: 'scroll'}}>
              <SyntaxHighlighter language="sql" style={darcula}>
                {sqlFormatter.format(debugSql)}
              </SyntaxHighlighter>
            </div>
            }


            {data && <div>
              <span className="font-bold my-3 text-lg">Data:</span>
              <DebugWindowReportsData data={data}/>
            </div>}
          </div>
        }
      </div>
    </div>
  );
}

DebugWindowReports.propTypes = {
  message: PropTypes.any.isRequired,
  shortMessage: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
};
