import PositiveImpactTabPanelPayloadComment from './payload-comment';
import React from 'react';

export default function PositiveImpactTabPanelQuestion({question, payloads}) {
  const {text} = question;

  return (
    <div className="py-3 text-text-1">
      <div className="flex justify-between items-center">
        <div className="text-text-2">
          {text}
        </div>
      </div>

      <div className="pt-2">
        {payloads.map((p, index) => {
          return (<PositiveImpactTabPanelPayloadComment index={index} payload={p} key={index}/>);
        })}
      </div>

    </div>
  );
}
