import {removeItemFromArray} from './helpers/array-helper';

const initialState = {
  files: [],
};

export default function filesReducer(state = initialState, action) {
  switch (action.type) {
    case 'FILES/FETCH_ALL':
      return {...state, errorFetchAll: false, files: action.files};

    case 'FILES/CLEAR_ALL':
      return {...state, files: []};

    case 'FILES/FETCH':
      return {...state, errorFetch: false, file: action.file};

    case 'FILES/CREATE':
      return {...state, errorCreate: false, files: [...action.files, ...state.files]};

    case 'FILES/REMOVE':
      return {...state, files: removeItemFromArray(state.files, 'id', action.fileId)};

      // ERRORS

    case 'FILES/ERROR/FETCH':
      return {...state, errorFetch: true, file: undefined};

    case 'FILES/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'FILES/ERROR/CREATE':
      return {...state, errorCreate: action.errors};

    default:
      return state;
  }
}
