import KeyFindingsShowMore from '../key-findings/key-findings-show-more';
import PositiveImpactTabPanel from './tab-panel';
import React, {useState} from 'react';

export default function PositiveImpactTabPanelWrapper({category}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <KeyFindingsShowMore activeCategoryRecord={category} expanded={expanded} setExpanded={setExpanded}/>
      <PositiveImpactTabPanel category={category} expanded={expanded}/>
    </div>
  );
}
