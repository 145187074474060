import React from 'react';
import ReactDOM from 'react-dom';

export function mountComponents(searchSelector) {
  const ujs = window.ReactRailsUJS;
  const nodes = ujs.findDOMNodes(searchSelector);

  for (let i = 0; i < nodes.length; ++i) {
    const node = nodes[i];
    const className = node.getAttribute(ujs.CLASS_NAME_ATTR);
    const constructor = ujs.getConstructor(className);
    const propsJson = node.getAttribute(ujs.PROPS_ATTR);
    const props = propsJson && JSON.parse(propsJson);
    const hydrate = node.getAttribute(ujs.RENDER_ATTR);
    const cacheId = node.getAttribute(ujs.CACHE_ID_ATTR);
    const turbolinksPermanent = node.hasAttribute(ujs.TURBOLINKS_PERMANENT_ATTR);

    if (!constructor) {
      const message = 'Cannot find component: \'' + className + '\'';
      if (console && console.log) {
        console.log('%c[react-rails] %c' + message + ' for element', 'font-weight: bold', '', node);
      }
      throw new Error(message + '. Make sure your component is available to render.');
    } else {
      let component = ujs.components[cacheId];
      if (component === undefined) {
        component = React.createElement(constructor, props);
        if (turbolinksPermanent) {
          ujs.components[cacheId] = component;
        }
      }

      if (hydrate && typeof ReactDOM.hydrate === 'function') {
        component = ReactDOM.hydrate(component, node);
      } else {
        // component = ReactDOM.render(component, node);
        component = ReactDOM.unstable_createRoot(node).render(component);
      }
    }
  }
}
