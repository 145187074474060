const map = [
  {path: '/'},
  {path: '/dashboard/projects', root: true},
  {path: '/dashboard/assessments'},
  {path: '/dashboard/reports', root: true},
];

export const indexToRoute = function(index) {
  return map[index];
};

export const routeToIndex = function(pathname) {
  const index = map.indexOf(map.find((p) => {
    if (p.root && pathname.includes(p.path)) {
      return true;
    }
    return p.path === pathname;
  }));
  return index < 0 ? undefined : index;
};
