import {removeItemFromArray} from './helpers/array-helper';

const initialState = {
  projectMemberships: [],
};

export default function projectMembershipsReducer(state = initialState, action) {
  switch (action.type) {
    case 'PROJECT_MEMBERSHIPS/FETCH_ALL':
      return {...state, errorFetchAll: false, projectMemberships: action.projectMemberships};

    case 'PROJECT_MEMBERSHIPS/CLEAR_ALL':
      return {...state, projectMemberships: []};

    case 'PROJECT_MEMBERSHIPS/CREATE':
      return {...state, errorCreate: false, projectMemberships: [action.projectMembership, ...state.projectMemberships]};

    case 'PROJECT_MEMBERSHIPS/REMOVE':
      return {...state,
        errorRemove: false,
        projectMemberships: removeItemFromArray(state.projectMemberships, 'id', action.projectMembershipId),
      };

    // ERRORS
    case 'PROJECT_MEMBERSHIPS/ERROR/CREATE':
      return {...state, errorCreate: action.data};

    case 'PROJECT_MEMBERSHIPS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'PROJECT_MEMBERSHIPS/ERROR/FETCH':
      return {...state, errorFetch: true};

    case 'PROJECT_MEMBERSHIPS/ERROR/REMOVE':
      return {...state, errorRemove: true};

    default:
      return state;
  }
}
