import {Column, Entity, Index, JoinColumn, OneToMany, OneToOne, PrimaryGeneratedColumn} from 'typeorm/browser';
import {TfUser} from './tf-user';
import {TfSubmissionResult} from "./tf-submission-result";

@Entity('tf-submissions')
export class TfSubmission {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({type: 'bigint'})
  @Index()
  userId: number

  @OneToOne((type) => TfUser, (user) => user.submission, { onDelete: 'CASCADE' })
  @JoinColumn()
  user: TfUser;

  @OneToMany((type) => TfSubmissionResult, (submissionResult) => submissionResult.submission)
  submissions: TfSubmissionResult[];
}
