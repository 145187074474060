import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';

export default function IsAdmin({children}) {
  return isAdmin(children);
}

export const isAdmin = function(component) {
  const {currentUser} = useSelector((state) => state.session, (prev, next) => { // eslint-disable-line
    return isEqual(prev, next);
  });

  if (!currentUser) {
    return null;
  }

  const {role} = currentUser;

  if (role !== 'admin' && role !== 'super_admin') {
    return null;
  }

  return component;
};
