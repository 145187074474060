import {calculatePercentage} from '../../../lib/reports/risk-level';
import {getHookStateProperties} from '../../../lib/hookstate/get-properties';
import React from 'react';

export default function ReportOutstandingQuestionAnswer({index, size, answer, usersCount}) {
  const {submissionsCount, text} = getHookStateProperties(answer, 'submissionsCount', 'text');

  const percentage = calculatePercentage(submissionsCount, usersCount).toFixed(1);
  const symbol = size === index ? '.' : ',';

  return (
    <span className="mx-1">
      <span className="font-semibold text-black-1">{percentage}%</span> said "{text}"{symbol}
    </span>
  );
}
