import Initializer from './config/initializer';
import React from 'react';
import TypeformEditor from './typeform/editor';

export default function MountTypeformScoresEditor({config, request, typeformId}) {
  return (
    <div>
      <Initializer config={config} request={request}>
        <TypeformEditor typeformId={typeformId}/>
      </Initializer>
    </div>
  );
}
