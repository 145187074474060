import KeyFindings from './key-findings';
import React from 'react';

export default function KeyFindingsWrapper({mainCategory, store, keyFindingsFiltertedCategories}) {
  if (mainCategory === 'Positive Impact Assessment') {
    return null;// <KeyFindingsShowMore mainCategory={mainCategory} store={store}/>;
  }

  return <KeyFindings mainCategory={mainCategory} store={store} keyFindingsFiltertedCategories={keyFindingsFiltertedCategories}/>;
}
