import '../styles/app.scss';
import 'trix/dist/trix';
import {mountComponents} from '../lib/react/patch-concurrent';
global = global || {};

const componentRequireContext = require.context('components', false);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.mountComponents = mountComponents;

ReactRailsUJS.useContext(componentRequireContext); /* eslint-disable-line react-hooks/rules-of-hooks */
