import React from 'react';
import ScoreBucket from './score-bucket';

export default function KeyFinding({keyFinding, category}) {
  const {score} = keyFinding;

  return (
    <div className="my-1 p-2 flex hover:bg-light-gray-1 hover:shadow-lg transition ease-in duration-100">
      {
        score ?
          <div className="pr-2 pt-1">
            <ScoreBucket score={score} category={category}/>
          </div> :
          null
      }

      <div className="text-text-1" dangerouslySetInnerHTML={{__html: keyFinding.answer.keyFinding}} />
    </div>
  );
}
