import {HelmetProvider} from 'react-helmet-async';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {WebSql} from './initializers/web-sql';
import {useBuildAxios} from '../../lib/axios';
import {useGlobal} from 'reactn';
import DateFnsUtils from '@date-io/date-fns';
import Head from './initializers/head';
import Notifications from './initializers/notifications';
import PropTypes from 'prop-types';
import React from 'react';
import Session from './initializers/session';
import Store from './initializers/store';
import colors from 'colors';

export default function Initializer({children, config, request}) {
  const [_, setEnv] = useGlobal('env'); /* eslint-disable-line no-unused-vars */
  setEnv({config, request});

  const railsEnv = config.env;
  if (railsEnv !== 'production') {
    console.log(colors.green(railsEnv)); // eslint-disable-line
    console.log(JSON.stringify(config)); // eslint-disable-line
  }

  useBuildAxios();

  return (
    <HelmetProvider>
      <Notifications>
        <WebSql>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Store>
              <Head/>
              <Session>
                {children}
              </Session>
            </Store>
          </MuiPickersUtilsProvider>
        </WebSql>
      </Notifications>
    </HelmetProvider>
  );
}

Initializer.propTypes = {
  children: PropTypes.element.isRequired,
  config: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
};
