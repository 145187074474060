import {TrixEditor} from 'react-trix';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import style from './text-area.module.scss';
import updateTextAction from '../../../actions/tf-forms/update-text';

export default function TrixField({form, answer, field, name, setEditor, setDisplayName}) {
  const ref = useRef();
  const [value, setValue] = useState(answer[field] || '');
  const dispatch = useDispatch();
  const [timeout, setTTimeout] = useState();

  function save() {
    if (isEmpty(answer[field]) && isEmpty(value) || answer[field] === value) {
      return setTimeout(() => setEditor(false), 200);
    }

    setDisplayName('Saving..');
    dispatch(updateTextAction(form, value, answer, field, () => {
      setDisplayName('Saved');
    }, () => {
      setDisplayName('Error saving');
    }, () => {
      setTTimeout(setTimeout(() => {
        setEditor(false);
        setDisplayName(name);
      }, 2000));
    }));
  }

  function clearTTimeout() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  useEffect(() => {
    ref.current.querySelector('trix-editor').focus();
  }, [ref]);

  return (
    <div onBlur={save} ref={ref} onFocus={clearTTimeout}>
      <TrixEditor
        className={style.textArea}
        value={value}
        placeholder={`Fill ${name}...`}
        autoFocus={true}
        onChange={setValue}
      />
    </div>
  );
}
