import ExportsDownloadButton from './download';
import React from 'react';

export default function ReportExportResponsesExcel({report, project, assessment}) {
  const url = `report_exports/${report.id}/excel_responses`;
  const fileName = `Canari responses for ${project.title} - ${assessment.phase}.xlsx`;

  return (
    <ExportsDownloadButton fileName={fileName} text={'Download responses'} params={{}} url={url}/>
  );
}
