import {removeItemFromArray, replaceItemFromArray} from './helpers/array-helper';

const initialState = {
  currentReport: {},
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case 'REPORTS/FETCH_ALL':
      return {...state, errorFetchAll: false, fetchAllLoading: false, reports: action.reports};

    case 'REPORTS/CLEAR_ALL':
      return {...state, reports: []};

    case 'REPORTS/FETCH_ALL/LOADING':
      return {...state, fetchAllLoading: true};

    case 'REPORTS/FETCH':
      return {...state, currentReport: action.report, errorFetch: false};

    case 'REPORTS/CREATE':
      return {...state, errorCreate: false, reports: [action.report, ...state.reports]};

    case 'REPORTS/REMOVE':
      return {...state, reports: removeItemFromArray(state.reports, 'id', action.reportId)};

    case 'REPORTS/REPLACE':
      return {...state, reports: replaceItemFromArray(state.reports, 'id', action.report.id, action.report)};

    // ERRORS
    case 'REPORTS/ERROR/CREATE':
      return {...state, errorCreate: action.data};

    case 'REPORTS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'REPORTS/ERROR/FETCH':
      return {...state, errorFetch: true};

    default:
      return state;
  }
}
