import {DonutChart} from 'bizcharts';
import {isEmpty} from 'lodash';
import React from 'react';
import useIsDebug from '../../../hooks/use-is-debug';

const chartSize = 110;
export default function ReportsPositiveImpactChart({category, currentScore}) {
  const {isDebugMode} = useIsDebug();

  if (isEmpty(currentScore)) {
    return null;
  }

  const data = [
    {name: category.displayTitle, value: currentScore.percentage.percentageCurrent},
    {name: 'Potential', value: currentScore.percentage.percentageTotal},
  ];

  return (
    <div>
      {
        isDebugMode && <div>
          Debug percentage: {currentScore.percentage.percentageCurrent}%
        </div>
      }

      <DonutChart
        className="mx-auto"
        data={data}
        title={{
          visible: true,
        }}
        meta={{
          name: {
            range: [0, 1],
          },
          value: {
            alias: 'value',
            formatter(v) {
              return `${v}%`;
            },
          },
        }}

        colorField="name"
        color={(name) => {
          if (name === 'Potential') {
            return 'hsl(206,8%,84%)';
          }
          return 'hsl(162, 63%, 41%)';
        }}

        height={chartSize}
        width={chartSize}
        angleField="value"
        legend={{
          visible: false,
        }}
        radius={1}
        innerRadius={0.63}
        statistic={{
          visible: false,
        }}

        label={{
          visible: false,
        }}
      />
    </div>
  );
}
