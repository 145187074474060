import {removeItemFromArray, replaceItemFromArray} from './helpers/array-helper';

const initialState = {
  users: [],
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case 'USERS/FETCH_ALL':
      return {...state, errorFetchAll: false, users: action.users};

    case 'USERS/CREATE':
      return {...state, errorCreate: false, users: [action.data, ...state.users]};

    case 'USERS/REMOVE':
      return {...state, users: removeItemFromArray(state.users, 'id', action.userId)};

    case 'USERS/REPLACE':
      return {...state, users: replaceItemFromArray(state.users, 'id', action.user.id, action.user)};

    // ERRORS
    case 'USERS/ERROR/CREATE':
      return {...state, errorCreate: action.data};

    case 'USERS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};
    default:
      return state;
  }
}
