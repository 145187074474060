import React from 'react';
import colorForScore from './color-for-score';
import style from './score-bucket.module.scss';

export default function ScoreBucket({score, category}) {
  const {score: value} = score;
  let bgColor = '';
  if (value && category) {
    bgColor = colorForScore(value, category);
  } else {
  }

  const className = `${bgColor} ${style.point} rounded-full border border-gray-600`;

  return (
    <div className={className}/>
  );
}
