import axios from '../../lib/axios';

export function updateScoreAction(form, answer, category, score, scoreRecord, cb, errorCb, alwaysCb) {
  const data = {score: {answerId: answer.id, categoryId: category.id, score}};

  return (dispatch) => {
    axios().post(`typeforms/${form.id}/update_score`, data).then(() => {
      cb();
      alwaysCb();
      dispatch(updateScore(score, scoreRecord, answer, category));
    }).catch(() => {
      errorCb();
      alwaysCb();
    });
  };
}

export function updateScore(score, scoreRecord, answer, category) {
  return {
    answer, category, score, scoreRecord,
    type: 'TYPEFORM/UPDATE_SCORE',
  };
}
