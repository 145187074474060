import LinkAction from '../../atoms/actions/link';
import React from 'react';
import axios from '../../../lib/axios';
import fileDownload from 'js-file-download';

export default function ExportsDownloadButton({text, url, params, fileName}) {
  function download() {
    axios().post(url, params, {responseType: 'blob'}).then((res) => {
      fileDownload(res.data, fileName);
    });
  }

  return (
    <LinkAction onClick={download}>
      {text}
    </LinkAction>
  );
}
