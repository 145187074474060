const initialState = {
  loggedIn: localStorage.getItem('logged-in') !== null,
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case 'SESSION/SET':
      return {...state, company: action.company, companyLicense: action.companyLicense,
        currentUser: action.currentUser,
        loggedIn: true,
        setError: false,
      };
    case 'SESSION/LOGOUT':
      return {...state, currentUser: undefined, loggedIn: false};

    // ERRORS
    case 'SESSION/ERRORS/SET':
      return {...state, currentUser: undefined, loggedIn: false, setError: true};
    default:
      return state;
  }
}
