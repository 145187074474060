import './tab-switcher.scss';
import {indexToRoute, routeToIndex} from './tab-switcher/map-routes';
import {useHistory, useLocation} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import React, {useEffect, useState} from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useAuthorization from '../../hooks/use-authorization';

export default function PageTabsSwitcher({children}) {
  const {currentUser, isAdmin} = useAuthorization({protectedRoute: false});
  const {pathname} = useLocation();
  const [activeRoute, setActiveRoute] = useState();
  const history = useHistory();

  function buildTabProp(routeName) {
    return {
      'aria-controls': `simple-tabpanel-${routeName}`,
      'id': `simple-tab-${routeName}`,
    };
  }

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    setActiveRoute(routeToIndex(pathname));
  }, [pathname, setActiveRoute, currentUser]);

  function handleChange(index) {
    const {path} = indexToRoute(index);
    if (pathname === path) {
      return;
    }
    return history.push(path);
  }

  function Label({children}) {
    return (
      <div>
        {children}
      </div>
    );
  }

  if (!currentUser) {
    return children;
  }

  return (
    <>
      <div id="page-tab-switcher" className="pt-3">
        {activeRoute === undefined ?
          null :
          <AppBar position="static" style={{boxShadow: 'none'}}>
            <Tabs value={activeRoute} aria-label="simple tabs example"
              className="bg-light-gray-1">
              <Tab onClick={() => {
                handleChange(0);
              }} label={<Label>Overview</Label>} {...buildTabProp(0)} />
              <Tab onClick={() => {
                handleChange(1);
              }} label={<Label>Projects</Label>} {...buildTabProp(1)} />
              <Tab onClick={() => {
                handleChange(2);
              }} label={<Label>Assessments</Label>} {...buildTabProp(2)} />

              {
                isAdmin ?
                <Tab onClick={() => {
                  handleChange(3);
                }} label={<Label>Reports</Label>} {...buildTabProp(3)} /> :
                null
              }
            </Tabs>
          </AppBar>
        }
      </div>
      {children}
    </>
  );
}
