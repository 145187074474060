import {clearClassName} from '../../../lib/string';
import {isEmpty} from 'lodash';
import React from 'react';
import logo from '../../../images/logo.png';

export default function LogoReleaseCanary({className}) {
  if (isEmpty(className)) {
    className = 'h-10';
  }

  return (
    <img className={`${clearClassName(className)}`} src={logo} alt="canari" />
  );
}
