import axios from '../../lib/axios';

export default function verifyManuallyAction(form, question, cb, errorCb, alwaysCb) {
  const data = {question: {questionId: question.id}};

  return (dispatch) => {
    axios().post(`typeforms/${form.id}/verify_manually`, data).then(() => {
      cb();
      alwaysCb();
      dispatch(verifyManually(question));
    }).catch(() => {
      errorCb();
      alwaysCb();
    });
  };
}

export function verifyManually(question) {
  return {
    question,
    type: 'TYPEFORM/VERIFY_MANUALLY',
  };
}
