import {calculateRiskLevel} from '../../../lib/reports/risk-level';
import {clearClassName} from '../../../lib/string';
import {sumBy} from 'lodash';
import React from 'react';

export default function ReportCategoryLevelTitle({mainCategory, store}) {
  const categories = store.helpers.categoriesForMain(mainCategory, store);

  const percentages = sumBy(categories, 'percentage');

  const percentage = parseInt((percentages / categories.length).toFixed(2));
  const riskLevel = calculateRiskLevel(percentage);
  let color;

  if (mainCategory === 'Positive Impact Assessment') {
    if (riskLevel === 'low') {
      color = 'text-1';
    } else if (riskLevel === 'high') {
      color = 'green-1';
    } else {
      color = 'light-blue-2';
    }
  } else if (mainCategory === 'Negative Impact Assessment') {
    if (riskLevel === 'low') {
      color = 'green-1';
    } else if (riskLevel === 'high') {
      color = 'red-4';
    } else {
      color = 'orange-1';
    }
  }

  return (
    <div>
      <div className="px-3 py-3 md:mr-40 flex items-center bg-white rounded-sm shadow-md">
        {mainCategory} Level is&nbsp;
        <div className={`text-${clearClassName(color)} capitalize font-semibold`}>
          {riskLevel}
        </div>
      </div>
    </div>
  );
}
