import {formatAsDate} from '../../lib/string';
import React from 'react';

export default function ReportFooter({store}) {
  const {report, assessment, project} = store;

  return (
    <div className="text-text-1">
      <div className="pt-6 md:pr-40 text-sm">
        <span>
          This report on <span className="font-bold">{project.title}</span> was prepared for the
        </span> <span className="font-bold">{assessment.phase}</span> <span>
          phase of the project on
        </span> <span className="font-bold">{formatAsDate(report.createdAt)}</span>. <b>{assessment.assessmentMembershipsCount}</b> <span>
          people were asked to provide data for this report
        </span>. <span className="font-bold">{assessment.assessmentMembershipsCountCompleted}</span> <span>responses were received.</span>
      </div>
    </div>
  );
}
