import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonAvatar from '../../atoms/person/avatar';
import React from 'react';

export default function NavbarAvatar({currentUser, handleProfileMenuOpen, menuId}) {
  if (!currentUser) {
    return (
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
    );
  }

  return (
    <PersonAvatar border={true} className="cursor-pointer" url={currentUser.avatarUrl} onClick={handleProfileMenuOpen}/>
  );
}
