import {TfSubmissionResult} from '../../records/tf-submission-result';
import {calculatePercentage, calculateRiskLevel} from '../../reports/risk-level';
import {getRepository} from 'typeorm/browser';

export async function categorySubmissionScores(category) {
  const {total, id} = category;

  const query = getRepository(TfSubmissionResult).createQueryBuilder('submissionResult')
      .where('scores.categoryId = :categoryId')
      .innerJoinAndSelect('submissionResult.answer', 'answer')
      .leftJoinAndSelect('submissionResult.submission', 'submission')
      .leftJoinAndSelect('submission.user', 'user')
      .leftJoinAndSelect('answer.scores', 'scores')
      .leftJoinAndSelect('answer.question', 'question')
      .setParameter('categoryId', id);

  const getMany = await query.getMany();

  const submissionScore = getMany.reduce((sum, submissionResult) => {
    const scoresSum = submissionResult.answer.scores.reduce((subSum, score) => {
      return subSum + score.score;
    }, 0);

    return sum + scoresSum;
  }, 0);


  const percentageCurrent = parseFloat(calculatePercentage((submissionScore), total).toFixed(1));
  const percentageTotal = parseFloat(calculatePercentage((total - submissionScore), total).toFixed(0.1));

  return {
    current: submissionScore,
    percentage: {
      percentageCurrent,
      percentageCurrentString: calculateRiskLevel(percentageCurrent),
      percentageTotal,
      percentageTotalString: calculateRiskLevel(percentageTotal),
    },
    query: query,
    submissionResults: getMany,
    total,
  };
}
