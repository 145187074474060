import {Link} from 'react-router-dom';
import {clearClassName} from '../../../lib/string';
import React from 'react';

export default function LinkAction({children, className, to, ...options}) {
  const displayClassName = `text-light-blue-2 hover:text-light-blue-1 cursor-pointer ${clearClassName(className)}`;

  if (to) {
    return (
      <Link to={to} className={displayClassName}>
        {children}
      </Link>
    );
  }


  return (
    <a className={displayClassName} {...options}>
      {children}
    </a>
  );
}
