import {getRepository} from 'typeorm/browser';
import {TfCategory as model} from '../records/tf-category';
import fastPushAll from './fast-push';

export async function push(report) {
  const repo = getRepository(model);
  const data = [];
  report.includes.categories.forEach((answer) => {
    data.push(answer);
  });

  await repo.clear();

  return fastPushAll(repo, model, data);
}
