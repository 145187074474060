import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Done';
import React from 'react';

export default function TypformStatus({status, fontSize = 'default'}) {
  return <span className="mr-2">
    {
      status === 'verified' ?
      <CheckIcon color="action" fontSize={fontSize}/> :
      <CancelIcon color="error" fontSize={fontSize}/>
    }
  </span>;
}
