import './categories.scss';
import React from 'react';
import TypeformCategoriesTotal from './total';

export default function TypeformCategoriesTotals({categories, questions}) {
  return (
    <div className="mb-32">
      <h3 className="text-2xl font-bold mb-3">Total scores of categories:</h3>

      <div id="category-totals" className="flex">
        {categories.map((category) => {
          return (
            <div key={category.id} className="category p-4">
              <TypeformCategoriesTotal category={category} questions={questions} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
