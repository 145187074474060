import {routeToTitle} from './sub-navbar/map-routes';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import useAuthorization from '../../hooks/use-authorization';

export default function SubNavbarPage() {
  const {loggedIn} = useAuthorization({protectedRoute: false});
  const [title, setTitle] = useState();
  const {pathname} = useLocation();
  const state = useSelector((state) => state);

  useEffect(() => {
    setTitle(routeToTitle(pathname, state));
  }, [setTitle, pathname, state]);

  if (!loggedIn) {
    return null;
  }

  return (
    <div className="bg-white-1 py-5 pl-5 shadow-md mb-3">
      <h2 className="text-text-1 pl-3">
        {title}
      </h2>
    </div>
  );
}
