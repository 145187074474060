export function calculateRiskLevel(percentage) {
  if (percentage < 40) {
    return 'low';
  }

  if (percentage > 75) {
    return 'high';
  }

  return 'medium';
}

export function calculatePercentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}
