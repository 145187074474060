import {TfCategory} from '../../records/tf-category';
import {TfQuestion} from '../../records/tf-question';
import {TfUser} from '../../records/tf-user';
import {addSubmissionsCountToAnswers} from './answer-occorunces';
import {getRepository} from 'typeorm/browser';

export async function findOutstandingQuestions() {
  const usersCount = await getRepository(TfUser).count();
  const positiveImpactCategory = await getRepository(TfCategory).findOne({title: 'Positive Impact'});
  const minSubmissionsForAnswers = usersCount / 2;
  let questions = [];

  const query = getRepository(TfQuestion).createQueryBuilder('question')
      .leftJoinAndSelect('question.answers', 'answers')
      .leftJoinAndSelect('answers.scores', 'scores')
      .leftJoinAndSelect('answers.submissions', 'submissions')
      .where('scores.categoryId != :categoryId')
      .setParameter('categoryId', positiveImpactCategory.id);

  if (usersCount < 2) {
    return {
      query,
      questions,
      usersCount,
    };
  }
  questions = await query.getMany();

  questions.forEach((question) => {
    addSubmissionsCountToAnswers(question.answers);
  });

  const filteredQuestions = questions.filter((question) => {
    let hasMoreThanHalf = false;
    question.answers.forEach((answer) => {
      if (answer.submissionsCount >= minSubmissionsForAnswers) hasMoreThanHalf = true;
    });


    return !hasMoreThanHalf;
  });

  return {
    outstandingQuestions: filteredQuestions,
    query,
    usersCount,
  };
}
