import {isEmpty} from 'lodash';
import DebugWindowReports from '../debug/window';
import PositiveImpactTabs from './tabs';
import React, {useEffect, useState} from 'react';
import positiveImpactCategoriesQuery from '../../../lib/reports-sql/queries/positive-impact-categories';
import useIsDebug from '../../../hooks/use-is-debug';

export default function ReportsPositiveImpactCharts({report}) {
  const [categories, setCategories] = useState([]);
  const {isDebugMode} = useIsDebug();
  const [debugSql, setDebugSql] = useState();
  const [activeCategory, setActiveCategory] = useState('0');

  useEffect(() => {
    const query = positiveImpactCategoriesQuery();
    if (isDebugMode) {
      setDebugSql(query.getSql());
    }

    query.getMany().then((result) => {
      setCategories(result);
    });
  }, [report, isDebugMode, setDebugSql]);

  if (isEmpty(categories)) {
    return null;
  }


  return (
    <div className="py-4">
      {
        isDebugMode &&
          <DebugWindowReports
            title="Positive impact charts query"
            shortMessage="Get all categories belonging to positive impact"
            message="Categories should exclude specifically the 'Positive Impact' category"
            debugSql={debugSql}
            data={categories}
          />
      }

      <PositiveImpactTabs categories={categories} activeCategory={activeCategory} setActiveCategory={setActiveCategory}/>
    </div>
  );
}
