import {clearClassName} from '../../../lib/string';
import Button from '@material-ui/core/Button';
import React from 'react';
import style from './button.module.scss';

export default function ButtonAction({children, loading, variant, disabled, ...options}) {
  let bgColor; let textColor; let bgHover;
  if (variant === 'danger') {
    bgColor = 'bg-red-4';
    textColor = 'text-white-1';
    bgHover = 'hover:bg-red-3';
  } else if (variant === 'success-green') {
    bgColor = 'bg-green-1';
    bgHover = 'hover:bg-green-2';
  } else {
    bgHover = 'hover:bg-light-blue-1';
  }

  bgColor = bgColor || 'bg-light-blue-2';
  textColor = textColor || 'text-white-1';

  if (disabled) {
    bgColor = 'bg-light-gray-1';
    textColor = 'text-text-2';
    bgHover = 'bg-light-gray-1';
  }

  return (
    <Button disabled={disabled || loading} className={`${clearClassName(style.buttonAction)} 
      ${clearClassName(bgColor)} 
      ${clearClassName(textColor)} 
      ${clearClassName(bgHover)} 
      shadow`} {...options}>
      {children}
    </Button>
  );
}
