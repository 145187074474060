import {addAnswers, addAssessment, addCategories, addProject, addQuestions, addReport, addUsers} from './add-basic';
import {
  buildRelationshipsAnswers,
  buildRelationshipsCategories,
  buildRelationshipsPayloads,
  buildRelationshipsQuestions,
  buildRelationshipsUsers,
} from './build-relationships';
import {calculateRiskPerCategory} from './calculations';
import addHelpers from './add-helpers';
import pushInDb from '../reports-sql/init';

export async function reportStoreBuilder(reduxReport) {
  const report = JSON.parse(JSON.stringify(reduxReport));
  const dbReport = JSON.parse(JSON.stringify(reduxReport));
  const store = {};
  await pushInDb(dbReport);

  addReport(store, report);
  addUsers(store, report);
  addAssessment(store, report);
  addCategories(store, report);
  addQuestions(store, report);
  addAnswers(store, report);
  addProject(store, report);
  buildRelationshipsAnswers(store, report);
  buildRelationshipsUsers(store, report);
  buildRelationshipsQuestions(store);
  buildRelationshipsCategories(store, report);

  addHelpers(store);
  buildRelationshipsPayloads(store, report);

  calculateRiskPerCategory(store);

  console.log(store);

  return store;
}
