import {useDispatch} from 'react-redux';
import ButtonAction from '../../atoms/actions/button';
import React, {useState} from 'react';
import verifyManuallyAction from '../../../actions/tf-forms/verify-manually';

export default function TypeformVerifyQuestionManually({form, question}) {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState('Verify');
  const dispatch = useDispatch();
  if (question.status === 'verified') {
    return null;
  }

  function verify() {
    setLoading(true);
    setLabel('Saving');

    dispatch(verifyManuallyAction(form, question, () => {
      setLabel('Verify');
    }, () => {
      setLabel('Error');
    }, () => {
      setLoading(false);
      setTimeout(() => {
        setLabel('Verify');
      }, 3000);
    }));
  }

  return (
    <div className="mr-2">
      <div className="pr-2">
        <ButtonAction onClick={verify} loading={loading}>
          {label}
        </ButtonAction>
      </div>
    </div>
  );
}
