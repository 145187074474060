import {removeItemFromArray} from './helpers/array-helper';

const initialState = {
  assessmentMemberships: [],
};

export default function assessmentMembershipsReducer(state = initialState, action) {
  switch (action.type) {
    case 'ASSESSMENT_MEMBERSHIPS/FETCH_ALL':
      return {...state, assessmentMemberships: action.assessmentMemberships, errorFetchAll: false};

    case 'ASSESSMENT_MEMBERSHIPS/CLEAR_ALL':
      return {...state, assessmentMemberships: []};

    case 'ASSESSMENT_MEMBERSHIPS/CREATE':
      return {...state, assessmentMemberships: [action.assessmentMembership, ...state.assessmentMemberships], errorCreate: false};

    case 'ASSESSMENT_MEMBERSHIPS/REMOVE':
      return {...state,
        assessmentMemberships: removeItemFromArray(state.assessmentMemberships, 'id', action.assessmentMembershipId),
        errorRemove: false,
      };

    // ERRORS
    case 'ASSESSMENT_MEMBERSHIPS/ERROR/CREATE':
      return {...state, errorCreate: action.data};

    case 'ASSESSMENT_MEMBERSHIPS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'ASSESSMENT_MEMBERSHIPS/ERROR/FETCH':
      return {...state, errorFetch: true};

    case 'ASSESSMENT_MEMBERSHIPS/ERROR/REMOVE':
      return {...state, errorRemove: true};

    default:
      return state;
  }
}
