import {PrismAsyncLight as SyntaxHighlighter} from 'react-syntax-highlighter';
import {darcula} from 'react-syntax-highlighter/dist/cjs/styles/prism';
import {isEmpty} from 'lodash';
import LinkAction from '../../atoms/actions/link';
import React, {useState} from 'react';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import shallowGetObject from '../../../lib/objects/shallow-get-object';

SyntaxHighlighter.registerLanguage('json', json);

export default function DebugWindowReportsDataCell({col}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const colIsObject = typeof col === 'object';

  const message = isExpanded ? 'Shrink' : 'Expand object';

  return (
    <div>
      {
        colIsObject && !isEmpty(col) && <div>
          <LinkAction onClick={() => setIsExpanded(!isExpanded)}>
            {message}
          </LinkAction>
        </div>
      }

      <FormattedCell colIsObject={colIsObject} result={col} isExpanded={isExpanded}/>
    </div>
  );
}


function FormattedCell({colIsObject, result, isExpanded}) {
  if (!colIsObject || result === null) {
    return <div dangerouslySetInnerHTML={{__html: result}} />;
  }

  return (
    <div>
      <SyntaxHighlighter language="json" style={darcula}>
        {JSON.stringify(shallowGetObject(result, isExpanded), null, '  ')}
      </SyntaxHighlighter>
    </div>
  );
}

