import React from 'react';
import TypeformAnswer from './answer';
import TypeformVerifyQuestionManually from './question/verify-manually';
import TypformStatus from './status';

export default function TypeformQuestion({form, question, categories}) {
  return (
    <div className="border-black border-solid border-t-2">
      <div className="flex items-center pt-2 sticky left-0" style={{width: '80rem'}}>
        <TypeformVerifyQuestionManually form={form} question={question}/>
        <h2 className="text-2xl">
          <TypformStatus status={question.status} fontSize="large"/>
          {question.text} <b>({question.questionType})</b>
        </h2>
      </div>

      <div className="pl-4">
        {
          question.answers.map((answer) => {
            return (
              <TypeformAnswer form={form} answer={answer} categories={categories} key={answer.id}/>
            );
          })
        }
      </div>
    </div>
  );
}
