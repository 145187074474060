import {isEmpty} from 'lodash';
import {rawRiskLevel} from '../../../lib/reports-sql/queries/raw-risk-level';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DebugWindowReports from '../debug/window';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, {useEffect, useState} from 'react';
import useIsDebug from '../../../hooks/use-is-debug';

export default function ReportRawRisk() {
  const {isDebugMode} = useIsDebug();
  const [report, setReport] = useState();
  const [riskLevel, setRiskLevel] = useState();
  const [currentScore, setCurrentScore] = useState();
  const [debugSql, setDebugSql] = useState();

  useEffect(() => {
    rawRiskLevel().then(({report, currentScore}) => {
      setReport(report);
      setRiskLevel(currentScore.percentage.percentageCurrentString);
      setCurrentScore(currentScore);
      if (isDebugMode) {
        setDebugSql(currentScore.query.getSql());
      }
    });
  }, [isDebugMode, setReport, setRiskLevel, setCurrentScore, setDebugSql]);

  if (isEmpty(report) || isEmpty(riskLevel) || isEmpty(currentScore)) {
    return null;
  }

  return (
    <div>
      {
        isDebugMode &&<DebugWindowReports
          title="Raw risk"
          shortMessage="Calculate % for raw risk"
          message={<div>
            Current score is <b>{currentScore.current}</b> out of total <b>{currentScore.total}</b>
            = <b>{currentScore.percentage.percentageCurrent}%</b>

            <div>

            </div>
          </div>}
          position="right"
          debugSql={debugSql}
          data={currentScore.submissionResults}
        />
      }

      <div className="px-4 py-4 md:mr-40 bg-white flex items-center rounded shadow-md">
        <span className="mr-2">
          <RawRiskIcon riskLevel={riskLevel}/>
        </span>

        <div>
          <span className="font-bold">{report.projectTitle}</span> has been assessed as a <span className="font-bold">{riskLevel} risk </span> AI system.
        </div>
      </div>
    </div>
  );
}

function RawRiskIcon({riskLevel}) {
  let icon;
  if (riskLevel === 'low') {
    icon = (<CheckCircleOutlineIcon className="text-green-1" fontSize="large"/>);
  } else if (riskLevel === 'high') {
    icon = (<HighlightOffIcon className="text-red-4" fontSize="large"/>);
  } else if (riskLevel === 'medium') {
    icon = (<ErrorOutlineIcon className="text-orange-1" fontSize="large"/>);
  }

  return icon;
}
