import React, {useMemo} from 'react';
import ReportsPositiveImpactChart from './positive-impact-chart';

export default function PositiveImpactTab({category}) {
  const {currentScore} = category;

  const chart = useMemo(() => {
    return (
      <ReportsPositiveImpactChart category={category} currentScore={currentScore}/>
    );
  }, [currentScore, category]);

  return (
    <div className="flex flex-col justify-center w-32 h-40">
      <div>
        {chart}
      </div>
      <div className="text-xs">
        {category.displayTitle}
      </div>
    </div>
  );
}
