import {useGlobal} from 'reactn';
import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';
let axiosInstance;

export default function() {
  return axiosInstance;
};

export const useBuildAxios = function() {
  const [{config}] = useGlobal('env');
  const {api: {url: apiUrl, crsf}} = config;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = crsf;

  axiosInstance = buildAxios(apiUrl);
};

export const buildAxios = function(apiUrl) {
  return applyCaseMiddleware(axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  }));
};
