import React from 'react';
import ScoreBucket from '../key-findings/score-bucket';

export default function KnowledgeGap({answer}) {
  const score = answer.scores[0].score;
  const category = {mainCategory: 'Knowledge Gaps'};

  return (
    <div className="px-3 py-3 bg-light-yellow-1 my-4 text-text-1 shadow-md flex items-center">
      <div className="pr-2">
        <ScoreBucket score={{score: score}} category={category}/>
      </div>

      <div className="font-semibold">
        <div dangerouslySetInnerHTML={{__html: answer.keyFinding}} />
      </div>
    </div>
  );
}
