import PersonAvatar from '../../atoms/person/avatar';
import React from 'react';

export default function PositiveImpactTabPanelPayloadComment({index, payload}) {
  return (
    <div className="my-4 pl-4">
      <div className="text-text-1">
        {payload}
      </div>

      <div className="flex items-center pt-1">
        <div>
          <PersonAvatar className="cursor-pointer h-7 w-7 "/>
        </div>

        <div className="ml-1 mt-1 text-text-1 font-semibold text-sm">
          User {index + 1}
        </div>
      </div>
    </div>
  );
}
