import {TfAnswer} from '../../records/tf-answer';
import {getRepository} from 'typeorm/browser';

export async function answersForCategory(category, customWheres) {
  let query = getRepository(TfAnswer).createQueryBuilder('answer')
      .leftJoinAndSelect('answer.scores', 'scores')
      .leftJoinAndSelect('answer.submissions', 'submissions')
      .where('scores.categoryId = :categoryId')
      .setParameter('categoryId', category.id);

  if (customWheres) {
    customWheres.forEach((customWhere) => {
      query = query.andWhere(customWhere);
    });
  }

  const answers = await query.getMany();

  return {
    answers,
    query,
  };
}
