import {flatten, isEqual, uniq} from 'lodash';
import {selectKeyFindings} from './calculations';

export default function addHelpers(store) {
  store.helpers = {
    calculateCurrentForCategory,
    categoriesForMain,
    keyFindingsForMainCategory,
    mainCategories,
    outStandingQuestions,
    positiveImpact,
    rawRisk,
  };
}

function calculateCurrentForCategory(category, users) {
  let current = 0;

  category.scores.forEach((score) => {
    current += numberOfSubmissionsPerAnswer(score, users) * score.score;
  });

  return current;
}

function numberOfSubmissionsPerAnswer(score, users) {
  let count = 0;

  users.forEach((user) => {
    user.answers.forEach((answer) => {
      if (answer.scores.indexOf(score) !== -1) {
        count += 1;
      }
    });
  });

  return count;
}

function rawRisk(store) {
  return findCategoryByName(store, 'Raw risk');
}

function mainCategories() {
  return ['Positive Impact Assessment', 'Negative Impact Assessment', 'AI Readiness'];
}

function categoriesForMain(mainCategory, store) {
  return store.categories.filter((c) => c.mainCategory === mainCategory);
}

function keyFindingsForMainCategory(mainCategory, store) {
  const occurrences = answerOccurrencesForMainCategory(mainCategory, store);
  const filteredOccurrences = selectKeyFindings(occurrences, store);
  return filteredOccurrences.filter((o) => o.answer.keyFinding);
}

function outStandingQuestions(store) {
  const questions = outStandingQuestionsAll(store);
  const positiveImpact = store.helpers.positiveImpact(store);

  return questions.filter((question) => {
    return question.answers.find((answer) => {
      return answer.scores.find((score) => {
        return score.categoryId !== positiveImpact?.id;
      });
    });
  });
}

function outStandingQuestionsAll(store) {
  if (store.users.length < 2) {
    return [];
  }

  const maxUsersCount = store.users.length / 2;

  const questions = store.questions.filter((q) => q.answers.length > maxUsersCount);

  return questions.map((question) => {
    return {
      ...question,
      occurrences: question.answers.map((a) => answerOccurrences(a, store)).filter((ac) => ac.occurrences > 0),
    };
  });
}

function answerOccurrences(answer, store) {
  let occurrences = 0;
  store.users.forEach((user) => {
    user.answers.forEach((_answer) => {
      if (isEqual(_answer, answer)) {
        occurrences += 1;
      }
    });
  });

  return {
    answer,
    occurrences,
  };
}

function answerOccurrencesForMainCategory(mainCategory, store) {
  const categories = categoriesForMain(mainCategory, store);
  const answers = uniq(flatten(categories.map((c) => c.answers)));
  return answers.map((a) => answerOccurrences(a, store)).filter((ac) => ac.occurrences > 0);
}

function positiveImpact(store) {
  return findCategoryByName(store, 'Positive Impact');
}

function findCategoryByName(store, name) {
  return store.categories.find((c) => c.title === name);
}
