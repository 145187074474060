import {keyFindingsForCategory} from '../../../lib/reports-sql/queries/key-findings-for-category';
import DebugWindowReports from '../debug/window';
import LinkAction from '../../atoms/actions/link';
import React, {useEffect, useState} from 'react';
import useIsDebug from '../../../hooks/use-is-debug';

export default function KeyFindingsShowMore({activeCategoryRecord, expanded, setExpanded}) {
  const {isDebugMode} = useIsDebug();
  const [debugSql, setDebugSql] = useState();
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    keyFindingsForCategory(activeCategoryRecord).then(({answers, query}) => {
      setAnswers(answers);
      if (isDebugMode) {
        setDebugSql(query.getSql());
      }
    });
  }, [activeCategoryRecord, setAnswers, isDebugMode]);

  const displayedAnswers = expanded ? answers : answers.slice(0, 3);

  return (
    <div>
      {
        isDebugMode &&
        <DebugWindowReports
          title="Key findings for Positive Impact"
          shortMessage={`PI key findings, filtered for ${activeCategoryRecord.title}`}
          message="Filtered key findings based on category"
          debugSql={debugSql}
          data={answers}
        />
      }

      <div>
        <div>
          <div className="flex items-baseline">
            <h3 className="text-black-1 font-bold text-xl">Key Findings</h3>
            <LinkAction className="ml-2 text-sm" onClick={() => setExpanded(!expanded)}>
              { expanded ?
                'Hide' :
                `Show all`
              }
            </LinkAction>
          </div>
        </div>

        <div className="text-text-1 py-3">
          {displayedAnswers.map((answer, index) => {
            return (
              <div className="my-2" key={index}>
                <div dangerouslySetInnerHTML={{__html: answer.keyFinding}} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
