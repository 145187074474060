import Container from '@material-ui/core/Container';
import NavBarPage from './navbar';
import PageFooter from './footer';
import PageTabsSwitcher from './tabs-switcher';
import React from 'react';
import SubNavbarPage from './sub-navbar';

export default function PageLayout({children}) {
  return (
    <div className="flex flex-col h-screen justify-between">
      <div>
        <NavBarPage/>
        <SubNavbarPage/>
        <Container maxWidth="lg">
          <PageTabsSwitcher>
            <main className="py-5">
              {children}
            </main>
          </PageTabsSwitcher>
        </Container>
      </div>
      <PageFooter/>
    </div>
  );
}
