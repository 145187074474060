import KeyFindingsWrapper from '../key-findings/key-findings-wrapper';
import React, {useState} from 'react';
import ReportCategoryLevelTitle from './level-title';
import ReportOutstandingQuestions from '../outstanding-questions/questions';
import ReportTitle from '../text/title';
import ReportsCategoryCharts from './charts';
import ReportsPositiveImpactCharts from '../positive-impact/positive-impact-charts';

export default function ReportCategoryLayout({category, store}) {
  const showCharts = category !== 'Positive Impact Assessment';
  const showLevel = category !== 'AI Readiness';
  const showOutStandingQuestions = category === 'Negative Impact Assessment';
  const showPositivePayloads = category === 'Positive Impact Assessment';
  const [keyFindingsFiltertedCategories, setTemporaryFiltertedCategories] = useState();

  return (
    <div className="pb-16">
      <ReportTitle className="font-bold text-xl">
        <div className="text-3xl text-black-0 pb-2">{category}</div>
      </ReportTitle>

      {
        showLevel ?
        <ReportCategoryLevelTitle mainCategory={category} store={store}/> :
        null
      }

      { showCharts ?
        <ReportsCategoryCharts mainCategory={category} store={store} setTemporaryFiltertedCategories={setTemporaryFiltertedCategories}/> :
        null
      }

      {
        showPositivePayloads ?
        <ReportsPositiveImpactCharts report={store.report}/> :
        null
      }

      { showOutStandingQuestions ?
        <div className="pt-4">
          <ReportOutstandingQuestions/>
        </div> :
        null
      }

      <div className="pt-8">
        <KeyFindingsWrapper mainCategory={category} store={store} keyFindingsFiltertedCategories={keyFindingsFiltertedCategories}/>
      </div>
    </div>
  );
}
