// import {TfCategory} from '../records/tf-category';
// import {getRepository} from 'typeorm/browser';
import {push as pushAnswers} from './push-answers';
import {push as pushCategories} from './push-categories';
import {push as pushQuestions} from './push-questions';
import {push as pushReport} from './push-report';
import {push as pushScores} from './push-scores';
import {push as pushSubmissionResults} from './push-submission-results';
import {push as pushSubmissions} from './push-submissions';
import {push as pushUsers} from './push-users';
// import {setcategoryTotal} from "./queries/category-submission-scores";

export default async function pushInDb(report) {
  console.log(report);
  await pushReport(report);
  await pushCategories(report);
  await pushQuestions(report);
  await pushAnswers(report);
  await pushUsers(report);
  await pushSubmissions(report);
  await pushScores(report);
  await pushSubmissionResults(report);

  // const categories = await getRepository(TfCategory).createQueryBuilder("category").getMany();
  // const categoriesPromises = categories.map((category) => {
  //   return setcategoryTotal(category)
  // });
  //
  // await Promise.all(categoriesPromises);
}
