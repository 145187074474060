import axios from '../../lib/axios';

export default function updateTextAction(form, payload, answer, field, cb, errorCb, alwaysCb) {
  const data = {text: {answerId: answer.id, field, payload}};

  return (dispatch) => {
    axios().post(`typeforms/${form.id}/update_text`, data).then(() => {
      cb();
      alwaysCb();
      dispatch(updateText(payload, answer, field));
    }).catch(() => {
      errorCb();
      alwaysCb();
    });
  };
}

export function updateText(payload, answer, field) {
  return {
    answer,
    field,
    payload,
    type: 'TYPEFORM/UPDATE_TEXT',
  };
}
