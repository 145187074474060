import {removeItemFromArray, replaceItemFromArray} from './helpers/array-helper';

const initialState = {
  currentProject: {},
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case 'PROJECTS/FETCH_ALL':
      return {...state, errorFetchAll: false, fetchAllLoading: false, projects: action.projects};

    case 'PROJECTS/CLEAR_ALL':
      return {...state, projects: []};

    case 'PROJECTS/FETCH_ALL/LOADING':
      return {...state, fetchAllLoading: true};

    case 'PROJECTS/FETCH':
      return {...state, currentProject: action.project, errorFetch: false};

    case 'PROJECTS/CREATE':
      return {...state, errorCreate: false, projects: [action.project, ...state.projects]};

    case 'PROJECTS/REMOVE':
      return {...state, projects: removeItemFromArray(state.projects, 'id', action.projectId)};

    case 'PROJECTS/REPLACE':
      return {...state, projects: replaceItemFromArray(state.projects, 'id', action.project.id, action.project)};

    // ERRORS
    case 'PROJECTS/ERROR/CREATE':
      return {...state, errorCreate: action.data};

    case 'PROJECTS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'PROJECTS/ERROR/FETCH':
      return {...state, errorFetch: true};

    default:
      return state;
  }
}
