import {combineReducers} from 'redux';
import assessmentMembershipsReducer from './assessment-memberships-reducer';
import assessmentsReducer from './assessments-reducer';
import filesReducer from './files-reducer';
import invitationsReducer from './invitations-reducer';
import projectMembershipsReducer from './project-memberships-reducer';
import projectsReducer from './projects-reducer';
import reportsReducer from './reports-reducer';
import sessionReducer from './session-reducer';
import typeformsReducer from './typeform-reducer';
import usersReducer from './users-reducer';

export const rootReducer = combineReducers({
  assessmentMemberships: assessmentMembershipsReducer,
  assessments: assessmentsReducer,
  files: filesReducer,
  invitations: invitationsReducer,
  projectMemberships: projectMembershipsReducer,
  projects: projectsReducer,
  reports: reportsReducer,
  session: sessionReducer,
  typeforms: typeformsReducer,
  users: usersReducer,
});
