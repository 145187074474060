import {calculateRiskLevel} from './risk-level';

export default function downloadParams(store, data) {
  const rawRisk = store.helpers.rawRisk(store);
  const riskLevel = calculateRiskLevel(rawRisk.percentage);

  return {
    reportInfo: {
      data,
      riskLevel,
    },
  };
}
