import {isEmpty} from 'lodash';

const exactMap = {
  '/': 'INQ App',
  '/dashboard/assessments': 'Assessments',
  '/dashboard/invitations': 'Invitations',
  '/dashboard/projects': 'Projects',
  '/dashboard/reports': 'Reports',
  '/dashboard/users': 'Company management',
};

const dynamicMap = {
  '/dashboard/projects/': (state) => {
    const {currentProject} = state.projects;
    if (isEmpty(currentProject)) {
      return;
    }

    return currentProject.title;
  },

  'dashboard/reports/': (state) => {
    const {currentReport} = state.reports;
    if (isEmpty(currentReport)) {
      return;
    }

    return `${currentReport.includes.project.title}: ${currentReport.includes.assessment.phase}`;
  },
};

export const routeToTitle = function(pathname, state) {
  let title = exactMap[pathname];
  if (!title) {
    const dynamicMapKey = Object.keys(dynamicMap).find((path) => pathname.indexOf(path) !== -1);
    if (dynamicMapKey) {
      title = dynamicMap[dynamicMapKey](state);
    }
  }

  return isEmpty(title) ? exactMap['/'] : title;
};
