import {Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn} from 'typeorm/browser';
import {TfSubmission} from "./tf-submission";
import {TfAnswer} from "./tf-answer";

@Entity('tf-submission-results')
export class TfSubmissionResult {
    @PrimaryGeneratedColumn()
    id: number;

    @Column({type: 'bigint'})
    @Index()
    submissionId: number

    @Column({type: "text", nullable: true})
    payload: string

    @ManyToOne(type => TfSubmission, submission => submission.submissions, { onDelete: 'CASCADE' })
    @JoinColumn()
    submission: TfSubmission;

    @Column({type: 'bigint'})
    @Index()
    answerId: number

    @ManyToOne(type => TfAnswer, answer => answer.submissions, { onDelete: 'CASCADE' })
    @JoinColumn()
    answer: TfAnswer;
}
