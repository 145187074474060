import {clearClassName} from '../../../lib/string';
import {isEmpty} from 'lodash';
import {updateScoreAction} from '../../../actions/tf-forms/update-score';
import {useDispatch} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';

export default function ScoreEditor({form, category, score, answer, setEditor}) {
  const [value, setValue] = useState(isEmpty(score) ? '' : score.score);
  const [label, setLabel] = useState(category.title);
  const [labelClassName, setLabelClassName] = useState();
  const [timeout, setTTimeout] = useState();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  function clearTTimeout() {
    if (timeout) {
      clearTimeout(timeout);
    }
  }

  function save() {
    if (score.score === value) {
      return setEditor(false);
    }
    setLabel('Saving...');
    setDisabled(true);
    if (timeout) {
      clearTimeout(timeout);
    }
    dispatch(updateScoreAction(form, answer, category, value, score, () => {
      setLabel('Saved');
      setLabelClassName('text-green-400');
    }, () => {
      setLabel('Not saved');
      setLabelClassName('text-red-400');
    }, () => {
      setDisabled(false);
      setTTimeout(setTimeout(() => {
        setEditor(false);
      }, 2000));
    }));
  }

  return (
    <div className="w-32">
      <TextField
        label={<span className={clearClassName(labelClassName)}>{label}</span>}
        type="number"
        value={value}
        onChange={({target}) => setValue(target.value)}
        onFocus={clearTTimeout}
        onBlur={save}
        inputRef={ref}
        disabled={disabled}
      />
    </div>
  );
}
