import {maxBy, reduce} from 'lodash';
import React, {useEffect, useState} from 'react';

export default function TypeformCategoriesTotal({category, questions}) {
  const [sumCategory, setSumCategory] = useState();

  useEffect(() => {
    const multipleChoiceQuestions = []; const normalQuestions = [];

    questions.forEach((question) => {
      if (question.questionType === 'multiple_choice') {
        return multipleChoiceQuestions.push(question);
      }
      normalQuestions.push(question);
    });

    const multipleChoiceQuestionsSum = reduce(multipleChoiceQuestions, (sum, question) => {
      const subSumAnswers = reduce(question.answers, (subSumA, answer) => {
        const subSumScores = reduce(answer.scores, (sumSumbS, score) => {
          if (!score.score || score.tfCategoryId !== category.id) {
            return sumSumbS;
          }

          return sumSumbS + parseInt(score.score);
        }, 0);
        return subSumA + subSumScores;
      }, 0);
      return sum + subSumAnswers;
    }, 0);

    const normalQuestionsSum = reduce(normalQuestions, (sum, question) => {
      const subSumAnswers = reduce(question.answers, (subSumA, answer) => {
        const filteredScores = answer.scores.filter((score) => score.tfCategoryId === category.id);

        const maxScore = maxBy(filteredScores, 'score');
        if (!maxScore || !maxScore.score) {
          return subSumA;
        }

        return subSumA + parseInt(maxScore.score);
      }, 0);
      return sum + subSumAnswers;
    }, 0);

    setSumCategory(multipleChoiceQuestionsSum + normalQuestionsSum);
  }, [questions, category]);

  if (sumCategory === undefined) {
    return null;
  }

  return (
    <div className="mx-4">
      <div className="font-bold mb-2">
        {category.title}
      </div>

      <div>
        { sumCategory }
      </div>
    </div>
  );
}
