import Avatar from '@material-ui/core/Avatar';
import React from 'react';

export default function PersonAvatar({url, className, border = false, ...options}) {
  let displayClassName = className || '';
  if (border) {
    displayClassName += ' border-2 border-light-blue-1';
  }

  return (
    <Avatar className={displayClassName} src={url} {...options} />
  );
}
