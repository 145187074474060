import {TfAnswer} from '../../../lib/records/tf-answer';
import {TfCategory} from '../../../lib/records/tf-category';
import {TfQuestion} from '../../../lib/records/tf-question';
import {TfReport} from '../../../lib/records/tf-report';
import {TfScore} from '../../../lib/records/tf-score';
import {TfSubmission} from '../../../lib/records/tf-submission';
import {TfSubmissionResult} from '../../../lib/records/tf-submission-result';
import {TfUser} from '../../../lib/records/tf-user';
import {createConnection} from 'typeorm/browser';
import {useCallback, useEffect, useState} from 'react';

export function WebSql({children}) {
  const [defaultConnection, setConnection] = useState();
  const setupConnection = useCallback(async () => {
    try {
      const connection = await createConnection({
        entities: [
          TfCategory,
          TfQuestion,
          TfAnswer,
          TfUser,
          TfSubmission,
          TfScore,
          TfSubmissionResult,
          TfReport,
        ],
        location: 'default',
        logging: ['error'],
        sqlJsConfig: {
          locateFile: (file) => `/${file}`,
        },
        synchronize: true,
        type: 'sqljs',
      });

      setConnection(connection);
    } catch (error) {
      console.log(error);
    }
  }, [setConnection]);

  useEffect(() => {
    if (defaultConnection) {
      return;
    }
    setupConnection();
  }, [defaultConnection, setupConnection]);


  if (!defaultConnection) {
    return null;
  }


  return children;
}
