import axios from '../../lib/axios';
import logoutAction from './logout';
import queryString from 'query-string';

export function setSession({currentUser, company, companyLicense}) {
  return {
    company,
    companyLicense,
    currentUser,
    type: 'SESSION/SET',
  };
}

export default function fetchSessionAction() {
  return (dispatch) => {
    if (!global.isSSR && !localStorage.getItem('logged-in')) {
      return;
    }
    if (window.__settingSession__) return;
    window.__settingSession__ = true;

    axios().post('session').then(({data: {user: currentUser, company, companyLicense}}) => {
      dispatch(setSession({company, companyLicense, currentUser}));
    }).catch(() => {
      dispatch(logoutAction());
    });
  };
}

export const setLogin = function({search, enqueueSnackbar, history, dispatch}) {
  const query = queryString.parse(search);

  if (!query['set-login']) {
    return;
  }
  enqueueSnackbar('Welcome');
  history.push('/');

  localStorage.setItem('logged-in', 'true');
  dispatch(fetchSessionAction());
};
