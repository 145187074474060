import {Link, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

export default function ActiveLink({activeClassNames, className, to, children}) {
  const {pathname} = useLocation();
  const [linkClassName, setClassName] = useState(className);

  useEffect(() => {
    if (pathname === to) {
      setClassName(className + ' ' + activeClassNames);
    } else {
      setClassName(className);
    }
  }, [pathname, activeClassNames, to, className]);

  return (
    <Link to={to} className={linkClassName}>
      {children}
    </Link>
  );
}

ActiveLink.propTypes = {
  activeClassNames: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};
