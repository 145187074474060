import {Route} from 'react-router-dom';
import PageLayout from '../../page/layout';
import PropTypes from 'prop-types';
import React from 'react';

export const LoadAsyncRoute = function(options) {
  return (
    <PageLayout>
      <Route {...options}/>
    </PageLayout>
  );
};

LoadAsyncRoute.propTypes = {
  component: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};
