import {KnowledgeGaps} from './knowledge-gaps/knowledge-gaps';
import {reportStoreBuilder} from '../../lib/reports/store-builder';
import React, {useEffect, useState} from 'react';
import ReportCategoryLayout from './category/layout';
import ReportFooter from './footer';
import ReportRawRisk from './information/raw-risk';
import ReportRecommendations from './recommendations/recommendations';
import ReportTitle from './title';

export default function ReportsLayout({report}) {
  const [store, setStore] = useState();

  useEffect(() => {
    reportStoreBuilder(report).then((store) => setStore(store));
  }, [report]);

  const distance = 'pt-8 px-4 sm:px-8';

  if (!store) {
    return <div className="text-center mx-4">
      <h3 className="text-text-1 font-bold">
        Building Report
      </h3>
    </div>;
  }

  return (
    <div>
      <div className={distance}>
        <ReportTitle store={store}/>
      </div>

      <div className={distance}>
        <ReportRawRisk/>
      </div>

      <div className={distance}>
        {store.helpers.mainCategories().map((category) => {
          return <ReportCategoryLayout category={category} store={store} key={category}/>;
        })}
      </div>

      <div className={distance}>
        <KnowledgeGaps/>
      </div>

      <div className={distance}>
        <ReportRecommendations store={store}/>
      </div>

      <div className={distance}>
        <ReportFooter store={store}/>
      </div>

    </div>
  );
}
