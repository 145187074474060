import {TfAnswer} from '../../records/tf-answer';
import {getRepository} from 'typeorm/browser';

export function answerOccurrencesForCategory(category) {
  return answerOccurrencesAll().where('scores.categoryId = :categoryId')
      .setParameter('categoryId', category.id);
}

export function addSubmissionsCountToAnswers(answers) {
  answers.forEach((answer) => {
    answer.submissionsCount = answer.submissions.length;
  });

  return answers;
}

export function answerOccurrencesAll() {
  return getRepository(TfAnswer).createQueryBuilder('answer')
      .leftJoinAndSelect('answer.scores', 'scores')
      .leftJoinAndSelect('answer.submissions', 'submissions');
}

