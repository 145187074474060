import {getRepository} from 'typeorm/browser';
import {TfUser as model} from '../records/tf-user';
import fastPushAll from './fast-push';

export async function push(report) {
  const repo = getRepository(model);
  const data = [];
  report.includes.submissions.forEach((submission) => {
    data.push(submission.user);
  });

  await repo.clear();

  return fastPushAll(repo, model, data);
}


