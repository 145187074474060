import './answer.scss';
import {TypeformTextFields} from './answers/text-fields';
import React from 'react';
import TypeformScore from './score';
import TypformStatus from './status';

export default function TypeformAnswer({form, answer, categories}) {
  return (
    <div className="pt-4 pb-2">
      <div className="sticky left-0" style={{width: '80rem'}}>
        <div className="sticky">
          <TypformStatus status={answer.status}/>
          <span className="font-bold">
            {answer.text}
          </span>
        </div>
      </div>

      <div className="flex ">
        <div className="text-xl w-1/2">
          <div>
            <TypeformTextFields answer={answer} form={form}/>
          </div>
        </div>

        <div className="flex tf-answer mx-1">
          {
            categories.map((category) => {
              const score = answer.scores.find((score) => score.tfCategoryId === category.id );
              return (
                <TypeformScore form={form} answer={answer} score={score} category={category} key={category.id}/>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}
