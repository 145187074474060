export default function shallowGetObject(object, recursive) {
  const result = {};
  for (const i in object) {
    const el = object[i];
    if (Array.isArray(el)) {
      if (recursive) {
        result[i] = el.map((val) => shallowGetObject(val, false));
      } else {
        result[i] = [];
      }
    } else if (typeof el === 'object') {
      if (recursive) {
        result[i] = shallowGetObject(el, false);
      } else {
        result[i] = {};
      }
    } else {
      result[i] = el;
    }
  }

  return result;
}
