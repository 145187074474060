export const removeItemFromArray = function(list, property, value) {
  return list.filter((item) => item[property] !== value);
};

export const replaceItemFromArray = function(list, property, value, newValue) {
  const replaceIndexes = [];
  const newList = [];
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (item[property] === value) {
      replaceIndexes.push(i);
    }
    newList.push(item);
  }


  replaceIndexes.forEach((index) => {
    newList[index] = newValue;
  });

  return newList;
};
