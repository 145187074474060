import ActiveLink from '../../atoms/navigation/active-link';
import IsAdmin from '../../authorization/is-admin';
import React from 'react';
import style from './list-links.module.scss';

export default function NavBarListLinks({loggedIn}) {
  function NavBarLink({to, children}) {
    return (
      <div className="px-3">
        <ActiveLink to={to} activeClassNames={`${style.activeLink} bg-blue-2 px-1`}
          className="px-4 py-3 rounded text-white-2">
          {children}
        </ActiveLink>
      </div>
    );
  }

  if (!loggedIn) {
    return null;
  }

  return (
    <div className="flex">
      <IsAdmin>
        <NavBarLink to="/dashboard/users">
          My company
        </NavBarLink>
      </IsAdmin>
    </div>
  );
}
