import {SnackbarProvider} from 'notistack';
import React from 'react';

export default function Notifications({children}) {
  return (
    <SnackbarProvider maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
}
