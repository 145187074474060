import {isEqual} from 'lodash';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';

export default function useAuthorization({role= 'member', protectedRoute = true} = {}) {
  const {currentUser, loggedIn, company, companyLicense} = useSelector((state) => state.session, (prev, next) => {
    return isEqual(prev, next);
  });

  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (!protectedRoute) {
      return;
    }
    if (!loggedIn) {
      handleUnauthorized(history, enqueueSnackbar);
      return;
    }
    if (!currentUser) {
      return;
    }

    if (currentUser.role === 'super_admin' || currentUser.role === 'admin') {
      return;
    }
    if (currentUser.role !== role) {
      handleUnauthorized(history, enqueueSnackbar);
      return;
    }
  }, [currentUser, loggedIn, history, enqueueSnackbar, protectedRoute, role]);

  if (!loggedIn) {
    return {loggedIn};
  }

  if (!currentUser) {
    return {loggedIn};
  }

  if (currentUser.role === 'super_admin' || currentUser.role === 'admin') {
    return {company, companyLicense, currentUser, isAdmin: true, loggedIn};
  }

  if (currentUser.role === role) {
    return {company, companyLicense, currentUser, loggedIn};
  }

  return {loggedIn};
}

function handleUnauthorized(history, enqueueSnackbar) {
  enqueueSnackbar('Unauthorized');
  history.push('/login');
}
