import {
  Column,
  Entity, OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm/browser';
import {TfScore} from "./tf-score";

@Entity('tf-categories')
export class TfCategory {
  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  title: string;

  @Column()
  displayTitle: string;

  @Column()
  mainCategory: string;

  @Column()
  total: number;

  @Column({nullable: true})
  current: number;

  @OneToMany((type) => TfScore, (score) => score.category)
  scores: TfScore[];
}
