import {isEmpty} from 'lodash';
import DebugWindowReportsDataCell from './data-cell';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

export default function DebugWindowReportsData({data}) {
  const [columns, setColumns] = useState();

  useEffect(() => {
    if (isEmpty(data)) {
      return;
    }

    const firstEl = data[0];

    setColumns(Object.keys(firstEl));
  }, [data, setColumns]);

  if (isEmpty(columns) || isEmpty(data)) {
    return null;
  }

  return (
    <div className="overflow-scroll" style={{fontSize: '0.75rem', maxHeight: '50rem'}}>
      <table className="shadow-lg bg-white w-full">
        <thead>
          <tr>
            {
              columns.map((col) => <th key={col} className="bg-blue-100 border text-left px-8 py-4">{col}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            data.map((el, index) => {
              return (
                <tr key={index}>
                  {
                    columns.map((col, index) => <td className="border px-8 py-4" key={index}>
                      <DebugWindowReportsDataCell col={el[col]}/>
                    </td>)
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

DebugWindowReportsData.propTypes = {
  data: PropTypes.array.isRequired,
};
