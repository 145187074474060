import React from 'react';
import TypeformTextArea from './text-area';

export function TypeformTextFields({form, answer}) {
  return (
    <div>
      <div>
        <TypeformTextArea form={form} answer={answer} field="note" name="Note"/>
      </div>

      <div className="pt-4">
        <TypeformTextArea form={form} answer={answer} field="recommendation" name="Recommendation"/>
        <TypeformTextArea form={form} answer={answer} field="keyFinding" name="Key Finding"/>
      </div>

      <div className="pt-16">
        <TypeformTextArea form={form} answer={answer} field="lowRiskRecommendation" name="Low Risk Recommendation"/>
        <TypeformTextArea form={form} answer={answer} field="mediumRiskRecommendation" name="Medium Risk Recommendation"/>
        <TypeformTextArea form={form} answer={answer} field="highRiskRecommendation" name="High Risk Recommendation"/>
      </div>
    </div>
  );
}
