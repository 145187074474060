import {findOutstandingQuestions} from '../../../lib/reports-sql/queries/outstanding-questions';
import {isEmpty} from 'lodash';
import {useState} from '@hookstate/core';
import DebugWindowReports from '../debug/window';
import LinkAction from '../../atoms/actions/link';
import React, {useEffect} from 'react';
import ReportOutstandingQuestion from './question';
import useIsDebug from '../../../hooks/use-is-debug';

export default function ReportOutstandingQuestions() {
  const {isDebugMode} = useIsDebug();
  const state = useState({
    debugSql: null,
    expanded: false,
    outstandingQuestions: [],
    usersCount: 0,
  });
  const {
    outstandingQuestions,
    usersCount,
    expanded,
    debugSql,
  } = state;

  useEffect(() => {
    findOutstandingQuestions().then(({usersCount, outstandingQuestions, query}) => {
      state.batch((s) => {
        s.merge({
          outstandingQuestions,
          usersCount,
        });
        if (isDebugMode) {
          s.merge({debugSql: query.getSql()});
        }
      });
    });
  }, [isDebugMode]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isEmpty(outstandingQuestions)) {
    return null;
  }

  const displayedQuestions = expanded.get() ? outstandingQuestions : outstandingQuestions.slice(0, 3);
  const showMore = outstandingQuestions.length > 3;

  return (
    <div>
      {
        isDebugMode &&<DebugWindowReports
          title="Outstanding questions"
          shortMessage="Questions which have answers with less than 50% response rate"
          message="Then filter which don't have scores as Positive impact"
          position="right"
          debugSql={debugSql.get()}
          data={state.outstandingQuestions.get()}
        />
      }

      <div className="flex items-baseline">
        <div>
          <h4 className="font-bold text-xl text-black-1">
            Outstanding Questions:
          </h4>
        </div>
        <div>
          {
            showMore ?
              <LinkAction className="ml-2 text-sm" onClick={() => state.merge({expanded: true})}>
                { expanded.get() ?
                  'Hide' :
                  `Show all (${outstandingQuestions.length})`
                }
              </LinkAction> :
              null
          }
        </div>
      </div>

      <div>
        {displayedQuestions.map((question) => {
          question = question.get();
          return (
            <ReportOutstandingQuestion usersCount={usersCount.get()} question={question} key={question.id}/>
          );
        },
        )}
      </div>
    </div>
  );
}
