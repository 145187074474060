import {TfCategory} from '../../records/tf-category';
import {TfUser} from '../../records/tf-user';
import {addSubmissionsCountToAnswers, answerOccurrencesForCategory} from './answer-occorunces';
import {getRepository} from 'typeorm/browser';
import {sortBy} from 'lodash';

export async function findKnowledgeGaps() {
  const knowledgeGapCategory = await getRepository(TfCategory).findOne({mainCategory: 'Knowledge Gaps'});

  let query = answerOccurrencesForCategory(knowledgeGapCategory);
  query = query.andWhere('answer.keyFinding IS NOT NULL');
  const answers = await query.getMany();
  addSubmissionsCountToAnswers(answers);

  const usersCount = await getRepository(TfUser).count();
  const filteredAnswers = answers.filter((answer) => {
    return answer.submissionsCount === usersCount;
  });

  const sortedAnswers = sortBy(filteredAnswers, (a) => {
    return a.scores[0].score;
  }).reverse();

  return {
    answers: sortedAnswers,
    query: query,
  };
}
