import {isEmpty, isEqual} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import TypeformCategoriesTotals from './categories/totals';
import TypeformQuestion from './question';
import TypformStatus from './status';
import tfFormFetchAction from '../../actions/tf-forms/fetch';

export default function TypeformEditor({typeformId}) {
  const {currentTypeform} = useSelector((state) => state.typeforms, (prev, next) => {
    return isEqual(prev, next);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tfFormFetchAction(typeformId));
  }, [dispatch, typeformId]);

  if (isEmpty(currentTypeform)) {
    return null;
  }

  const {form, questions, categories} = currentTypeform;

  return (
    <div>
      <div className="overflow-x-scroll">
        <TypeformCategoriesTotals categories={categories} questions={questions}/>
      </div>

      <div className="overflow-x-scroll">
        <h1 className="text-4xl">
          <TypformStatus status={form.status} fontSize="large"/>
          {form.title}
        </h1>

        <div className="pt-8 pl-1" style={{width: 'fit-content'}}>
          {
            questions.map((question) => {
              return <TypeformQuestion form={form} question={question} categories={categories} key={question.id}/>;
            })
          }
        </div>
      </div>
    </div>
  );
}
