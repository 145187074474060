import {Column, Entity, OneToOne, PrimaryGeneratedColumn} from 'typeorm/browser';
import {TfSubmission} from './tf-submission';

@Entity('tf-users')
export class TfUser {
    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @Column({nullable: true})
    avatarUrl: string;

    @OneToOne((type) => TfSubmission, (submission) => submission.user)
    submission: TfSubmission;
}
