import {categorySubmissionScores} from '../../../lib/reports-sql/queries/category-submission-scores';
import {isEmpty} from 'lodash';
import DebugWindowReports from '../debug/window';
import PositiveImpactTab from './tab';
import PositiveImpactTabPanelWrapper from './tab-panel-wrapper';
import React, {useEffect, useState} from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import useIsDebug from '../../../hooks/use-is-debug';

export default function PositiveImpactTabs({categories, activeCategory, setActiveCategory}) {
  const {isDebugMode} = useIsDebug();
  const [debugSql, setDebugSql] = useState();

  const [categoriesWithScores, setCategoriesWithScores] = useState();

  useEffect(() => {
    const promises = categories.map((category, index) => {
      const result = categorySubmissionScores(category);

      return result.then((currentScore) => {
        category.currentScore = currentScore;
        if (isDebugMode && index === 0) {
          setDebugSql(currentScore.query.getSql());
        }
      });
    });

    Promise.all(promises).then(() => {
      setCategoriesWithScores(categories);
    });

    return () => {
      setCategoriesWithScores([]);
    };
  }, [categories, isDebugMode]);

  function changeTab(_, value) {
    setActiveCategory(value);
  }

  if (isEmpty(categoriesWithScores)) {
    return null;
  }

  return (
    <div id="report-positive-impact-switcher">
      {
        debugSql && <div className="my-2">
          <DebugWindowReports
            title="Wheel chart"
            shortMessage="Sample query for calculating charts"
            message={`Get all TfSubmissionResults for category ${categoriesWithScores[0].displayTitle}`}
            position="right"
            debugSql={debugSql}
            data={categoriesWithScores[0].currentScore.submissionResults}
          />
        </div>
      }

      <TabContext value={activeCategory}>
        <TabList onChange={changeTab} aria-label="kf switcher" scrollButtons="on" className="flex justify-center">
          {categories.map((category, index) => {
            return (
              <Tab value={index.toString()} label={<PositiveImpactTab category={category}/>} key={category.id}/>
            );
          })}
        </TabList>

        {categories.map((category, index) => {
          return (
            <TabPanel value={index.toString()} key={category.id}>
              <PositiveImpactTabPanelWrapper category={category}/>
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
}
