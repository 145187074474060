import {flatten, isEmpty, uniq} from 'lodash';

export function buildRelationshipsUsers(store, report) {
  store.users.forEach((user) => {
    const {answerIds} = report.includes.submissions.find((submission) => {
      return submission.user.id === user.id;
    });
    user.answers = answerIds.map((id) => store.answers.find((answer) => answer.id === id));

    user.answers.forEach((answer) => {
      answer.users = answer.users || [];
      answer.users.push(user);
    });
  });
}

export function buildRelationshipsAnswers(store, report) {
  store.answers.forEach((answer) => {
    const {id} = answer;
    answer.scores = report.includes.scores.filter((score) => score.answerId === id);
    answer.scores.forEach((score) => score.answer = answer);
  });
}

export function buildRelationshipsQuestions(store) {
  store.questions.forEach((question) => {
    question.answers = store.answers.filter((a) => a.questionId === question.id);

    question.answers.forEach((answer) => {
      answer.question = question;
    });
  });
}

export function buildRelationshipsCategories(store, report) {
  store.categories.forEach((category) => {
    category.scores = report.includes.scores.filter((score) => score.categoryId === category.id);

    category.answers = uniq(category.scores.map((score) => score.answer));
  });
}

export function buildRelationshipsPayloads(store, report) {
  const submissions = report.includes.submissions.filter((s) => !isEmpty(s.payloads));
  submissions.forEach((submission) => {
    submission.payloads.forEach((p) => p.user = submission.user);
  });

  const payloads = flatten(submissions.map(((s) => s.payloads)));
  const positiveImpact = store.helpers.positiveImpact(store);

  payloads.forEach((payload) => {
    const answer = store.answers.find((a) => a.id === payload.answerId);
    const exists = positiveImpact?.answers?.find((a) => a.id === answer.id);
    if (!exists) {
      return;
    }

    answer.question.payloads = answer.question.payloads || [];
    answer.question.payloads.push(payload);
  });
}
