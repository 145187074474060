export default function updateTextTypeform(currentTypeform, action) {
  const newTypeForm = JSON.parse(JSON.stringify(currentTypeform));
  const {field, payload, answer} = action;

  let newAnswer;

  const question = newTypeForm.questions.find((q) => {
    return q.answers.find((a) => {
      if (a.id === answer.id) {
        newAnswer = a;
        return true;
      }
    });
  });

  if (!newAnswer) {
    return;
  }

  question.status = 'verified';
  newAnswer.status = 'verified';
  newAnswer[field] = payload;

  newTypeForm.form.status = newTypeForm.questions.find((q) => q.status === 'unverified') ? 'unverified' : 'verified';

  return newTypeForm;
}
