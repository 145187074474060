import {reverse, sortBy} from 'lodash';
import KeyFinding from './key-finding';
import React from 'react';

export default function Group({groupeKfs}) {
  const {keyFindings, category} = groupeKfs;
  let keyFindingsSorted;

  if (category.percentageTitle === 'All') {
    keyFindingsSorted = keyFindings;
  } else {
    keyFindingsSorted = keyFindings.map((kf) => {
      const score = kf.answer.scores.find((score) => score.categoryId === category.id);

      return {
        ...kf,
        score: score,
      };
    });

    keyFindingsSorted = reverse(sortBy(keyFindingsSorted, (kf) => kf.score.score));
  }

  return (
    <div>
      {
        keyFindingsSorted.map((kf) => {
          return (
            <KeyFinding key={kf.answer.id} keyFinding={kf} category={category}/>
          );
        })
      }
    </div>
  );
}
