import {ColumnChart} from 'bizcharts';
import {sortBy} from 'lodash';
import React, {useEffect} from 'react';

export default function ReportsCategoryCharts({mainCategory, store, setTemporaryFiltertedCategories}) {
  const categories = store.helpers.categoriesForMain(mainCategory, store);
  const clonedCategories = categories.map((c) => {
    return {id: c.id, mainCategory, percentage: c.percentage, percentageTitle: c.percentageTitle};
  });
  const sortedCategories = sortBy(clonedCategories, 'percentageTitle');
  let name;
  let interpretation;
  if (mainCategory === 'AI Readiness') {
    name = 'Levels Of AI Readiness';
    interpretation = 'Higher is better.';
  } else if (mainCategory === 'Negative Impact Assessment') {
    name = 'Degree of Risk';
    interpretation = 'Higher is worse.';
  }

  useEffect(() => {
    setTemporaryFiltertedCategories(sortedCategories);
  }, [sortedCategories, setTemporaryFiltertedCategories]);

  return (
    <div>
      {
        name ?
        <div>
          <div className="my-6 text-text-2 font-semibold text-lg">
            {name}
          </div>
          <div className="my-4 text-text-2 text-sm">
            {interpretation}
          </div>
        </div> :
        null
      }

      <ColumnChart
        data={sortedCategories}
        xField="percentageTitle"
        yField="percentage"
        color="hsl(209, 18%, 30%)"
        columnSize={20}
        xAxis={{
          label: {
            style: {
              fontSize: 10,
            },
          },
          title: {
            visible: false,
          },
        }}
        yAxis={{
          label: {
            style: {
              fontSize: 12,
            },
          },
          max: 100,
          title: {
            visible: false,
          },
        }}
      />
    </div>
  );
}
