export function addMissingScores(tfForm) {
  const {questions, categories} = tfForm;
  questions.forEach((question) => {
    question.answers.forEach((answer) => {
      categories.forEach((category) => {
        const score = answer.scores.find((score) => {
          return score.categoryId === category.id;
        });

        if (!score) {
          answer.scores.push({
            id: `${answer.id}-${category.id}`,
            score: '',
            tfCategoryId: category.id,
          });
        }
      });
    });
  });

  return tfForm;
}
