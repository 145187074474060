import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import NavbarAvatar from './avatar';
import React from 'react';

export default function NavbarRightButton({isMobile, handleProfileMenuOpen, menuId, currentUser}) {
  if (!currentUser) {
    return null;
  }

  if (isMobile) {
    return (
      <IconButton
        aria-label="show more"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <MoreIcon />
      </IconButton>
    );
  } else {
    return (
      <NavbarAvatar handleProfileMenuOpen={handleProfileMenuOpen} menuId={menuId} currentUser={currentUser }/>
    );
  }
}
