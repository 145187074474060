import {removeItemFromArray} from './helpers/array-helper';

const initialState = {
  invitations: [],
};

export default function invitationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'INVITATIONS/FETCH_ALL':
      return {...state, errorFetchAll: false, invitations: action.invitations};

    case 'INVITATIONS/FETCH':
      return {...state, errorFetch: false, invitation: action.invitation};

    case 'INVITATIONS/CREATE':
      return {...state, errorCreate: false, invitations: [action.invitation, ...state.invitations]};

    case 'INVITATIONS/REMOVE':
      return {...state, invitations: removeItemFromArray(state.invitations, 'id', action.invitationId)};

      // ERRORS

    case 'INVITATIONS/ERROR/FETCH':
      return {...state, errorFetch: true, invitation: undefined};

    case 'INVITATIONS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'INVITATIONS/ERROR/CREATE':
      return {...state, errorCreate: action.errors};

    default:
      return state;
  }
}
