import {useGlobal} from 'reactn';
import React from 'react';
const Router = global.isSSR ? require('react-router-dom').StaticRouter : require('react-router-dom').BrowserRouter;
import {LoadAsyncRoute} from './routes/load-async';
import {Switch} from 'react-router-dom';
import colors from 'colors';
import loadable from '@loadable/component';

const HomePage = loadable(() => import('../pages/static/home'));
const AboutPage = loadable(() => import('../pages/static/about'));
const LoginPage = loadable(() => import('../pages/static/login'));
const Page404 = loadable(() => import('../pages/static/404'));
const InvitationPage = loadable(() => import('../pages/authentication/invitation'));
const DashboardProjectsViewPage = loadable(() => import('../pages/dashboard/projects/show'));
const DashboardProjectsPage = loadable(() => import('../pages/dashboard/projects'));
const DashboardUsersPage = loadable(() => import('../pages/dashboard/users'));
const DashboardInvitationsPage = loadable(() => import('../pages/dashboard/invitations'));
const DashboardProfilePage = loadable(() => import('../pages/dashboard/profile'));
const AssessmentsPage = loadable(() => import('../pages/dashboard/assessments'));
const ReportsPage = loadable(() => import('../pages/dashboard/reports'));
const DashboardReportsViewPage = loadable(() => import('../pages/dashboard/reports/show'));

export default function Routes() {
  const [{request}] = useGlobal('env');
  const initialUrl = request.url;
  console.log(colors.red(`GET ${initialUrl}`));

  function BuildRouter({children}) {
    const options = {children};
    if (global.isSSR) {
      options.location = initialUrl;
    }
    return <Router {...options}/>;
  }

  return (
    <BuildRouter>
      <Switch>
        <LoadAsyncRoute path="/dashboard/profile" component={DashboardProfilePage}/>
        <LoadAsyncRoute path="/dashboard/reports/:id" component={DashboardReportsViewPage}/>
        <LoadAsyncRoute path="/dashboard/reports" component={ReportsPage}/>
        <LoadAsyncRoute path="/dashboard/projects/:id" component={DashboardProjectsViewPage}/>
        <LoadAsyncRoute path="/dashboard/projects" component={DashboardProjectsPage}/>
        <LoadAsyncRoute path="/dashboard/invitations" component={DashboardInvitationsPage}/>
        <LoadAsyncRoute path="/dashboard/assessments" component={AssessmentsPage}/>
        <LoadAsyncRoute path="/dashboard/users" component={DashboardUsersPage}/>
        <LoadAsyncRoute path="/invitation/:token" component={InvitationPage}/>
        <LoadAsyncRoute path="/about" component={AboutPage}/>
        <LoadAsyncRoute path="/login" component={LoginPage}/>
        <LoadAsyncRoute path="/" exact={true} component={HomePage}/>
        <LoadAsyncRoute path="/*" component={Page404}/>
      </Switch>
    </BuildRouter>
  );
}
