import {Column, Entity, PrimaryGeneratedColumn} from 'typeorm/browser';

@Entity('tf-reports')
export class TfReport {
    @PrimaryGeneratedColumn()
    id: number;

    @Column({type: "text"})
    projectTitle: string;

    @Column({type: "datetime"})
    createdAt: string;
}
