import React from 'react';

export default function Recommendation({recommendationKey, answer}) {
  const {question} = answer;
  const recommendation = answer[recommendationKey] ? answer[recommendationKey] : answer.recommendation;

  const displayedText = recommendation.replace(/high risk/i, '');

  return (
    <div className="px-3 py-3 bg-light-yellow-1 my-4 text-text-1 shadow-md">
      <div className="text-xs">
        <div className="italic">
          {question.text}
        </div>
      </div>
      <div className="font-semibold">
        <div dangerouslySetInnerHTML={{__html: displayedText}} />
      </div>
    </div>
  );
}
