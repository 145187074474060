export default function verifyQuestionTypeform(currentTypeform, action) {
  const {question} = action;

  const newTypeForm = JSON.parse(JSON.stringify(currentTypeform));

  const newQuestion = newTypeForm.questions.find((q) => q.id === question.id);

  if (!newQuestion) {
    return;
  }

  newQuestion.status = 'verified';
  newQuestion.answers.forEach((answer) => {
    answer.status = 'verified';
  });

  newTypeForm.form.status = newTypeForm.questions.find((q) => q.status === 'unverified') ? 'unverified' : 'verified';

  return newTypeForm;
}
