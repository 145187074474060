import updateScoreTypeform from './typeform-reducer/update-score';
import updateTextTypeform from './typeform-reducer/update-text';
import verifyQuestionTypeform from './typeform-reducer/verify-question';

const initialState = {
  currentTypeform: {},
};

export default function typeformsReducer(state = initialState, action) {
  switch (action.type) {
    case 'TYPEFORM/FETCH':
      return {...state, currentTypeform: action.typeform, errorFetch: false};

    case 'TYPEFORM/UPDATE_SCORE':
      return {...state, currentTypeform: updateScoreTypeform(state.currentTypeform, action)};

    case 'TYPEFORM/UPDATE_TEXT':
      return {...state, currentTypeform: updateTextTypeform(state.currentTypeform, action)};

    case 'TYPEFORM/VERIFY_MANUALLY':
      return {...state, currentTypeform: verifyQuestionTypeform(state.currentTypeform, action)};

    default:
      return state;
  }
}
