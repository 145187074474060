import axios from '../../lib/axios';

export function logOut() {
  return {
    type: 'SESSION/LOGOUT',
  };
}


export default function logoutAction() {
  window.__settingSession__ = false;
  return (dispatch) => {
    axios().post('session/logout').then(() => {
      localStorage.removeItem('logged-in');
      dispatch(logOut());
    });
  };
}
