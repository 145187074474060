import {removeItemFromArray, replaceItemFromArray} from './helpers/array-helper';

const initialState = {
  currentAssessment: {},
};

export default function assessmentsReducer(state = initialState, action) {
  switch (action.type) {
    case 'ASSESSMENTS/FETCH_ALL':
      return {...state, assessments: action.assessments, errorFetchAll: false, fetchAllLoading: false};

    case 'ASSESSMENTS/FETCH_ALL/LOADING':
      return {...state, fetchAllLoading: true};

    case 'ASSESSMENTS/FETCH':
      return {...state, currentAssessment: action.assessment, errorFetch: false};

    case 'ASSESSMENTS/CREATE':
      return {...state, assessments: [action.assessment, ...state.assessments], errorCreate: false};

    case 'ASSESSMENTS/REMOVE':
      return {...state, assessments: removeItemFromArray(state.assessments, 'id', action.assessmentId)};

    case 'ASSESSMENTS/REPLACE':
      return {...state, assessments: replaceItemFromArray(state.assessments, 'id', action.assessment.id, action.assessment)};

    // ERRORS
    case 'ASSESSMENTS/ERROR/CREATE':
      return {...state, errorCreate: action.data};

    case 'ASSESSMENTS/ERROR/FETCH_ALL':
      return {...state, errorFetchAll: true};

    case 'ASSESSMENTS/ERROR/FETCH':
      return {...state, errorFetch: true};

    default:
      return state;
  }
}
