import {
    Column,
    Entity,
    Index,
    JoinColumn,
    ManyToOne,
    PrimaryGeneratedColumn
} from 'typeorm/browser';
import {TfCategory} from './tf-category';
import {TfAnswer} from "./tf-answer";

@Entity('tf-scores')
export class TfScore {
    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    score: number

    @Column({type: 'bigint'})
    @Index()
    categoryId: number

    @ManyToOne(type => TfCategory, category => category.scores, { onDelete: 'CASCADE' })
    @JoinColumn()
    category: TfCategory;

    @Column({type: 'bigint'})
    @Index()
    answerId: number

    @ManyToOne(type => TfAnswer, answer => answer.scores, { onDelete: 'CASCADE' })
    @JoinColumn()
    answer: TfAnswer;
}
